<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { goodsAvailableStock } from '@/api/store/report' //仓库报表

export default {
  name: 'usableStock',
  components: { TablePage },
  data () {
    return {
      options: {
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        getListApi: goodsAvailableStock,
        body: {},
        listNo: true, // 序号
        title: '单据信息',
        // labelWidth: "0",
        check: [], // 选中数据
        exportOption: {
          show: true,
          exportApi: 'exportGoodsAvailableStock',
          exportName: '商品可用库存查询',
          timeout: 60*5*1000
        },
        search: [
          {
            type: "input",
            tip:"商品编号、商品名称、类别编号、类别名称、仓库编号、仓库名称",
            model: "",
            filter: "query",
          },
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          {
            label: '可用库存',
            type: "input",
            seniorSearch: true,
            tip:"起",
            model: '',
            filter: 'startQty',
          },
          {
            label: '可用库存',
            type: "input",
            tip:"终",
            seniorSearch: true,
            model: '',
            filter: 'endQty',
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          {
            prop: 'categoryNo',
            label: '类别编号',
            minWidth: 155
          },
          {
            prop: 'categoryName',
            label: '类别名称',
            minWidth: 120,
          },
          {
            prop: 'goodsNo',
            label: '商品编号',
            minWidth: 120
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 160
          },
          {
            prop: 'barcode',
            label: '商品条码',
            minWidth: 120
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 150
          },
          {
            prop: 'storeNo',
            label: '仓库编号',
            minWidth: 150
          },
          {
            prop: 'storeName',
            label: '仓库名称',
            minWidth: 150
          },
          {
            prop: 'unitName',
            label: '基本单位',
            minWidth: 150
          },
          {
            prop: 'salePrice',
            label: '售价',
            minWidth: 150
          },
          {
            prop: 'finalQty',
            label: '库存',
            minWidth: 150
          },
          {
            prop: 'canQty',
            label: '可用数量',
            minWidth: 150
          },
        ],
      }
    }
  },
  methods: {
    seniorSearch () {},
  }
}
</script>
<style lang="scss" scoped></style>
