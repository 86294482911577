var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stock-query" },
    [
      _c(
        "el-card",
        { attrs: { "body-style": { padding: "10px 10px 0 10px" } } },
        [
          _c(
            "el-form",
            [
              _c("searchTitle", {
                on: { clickSearch: _vm.getList },
                scopedSlots: _vm._u([
                  {
                    key: "selectTop",
                    fn: function () {
                      return [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品类别" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "billType marR5",
                                attrs: {
                                  placeholder: "请选择",
                                  filterable: "",
                                  clearable: "",
                                  multiple: "",
                                  "collapse-tags": "",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.params.categoryIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.params, "categoryIds", $$v)
                                  },
                                  expression: "params.categoryIds",
                                },
                              },
                              _vm._l(_vm.categoryData, function (item) {
                                return _c("el-option", {
                                  key: item.categoryId,
                                  attrs: {
                                    label: item.categoryName,
                                    value: item.categoryId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商品名称" } },
                          [
                            _c("SelectRemote", {
                              attrs: {
                                option: _vm.goodsOption,
                                filterable: true,
                                multiple: true,
                                clearable: true,
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.params.goodsIds,
                                callback: function ($$v) {
                                  _vm.$set(_vm.params, "goodsIds", $$v)
                                },
                                expression: "params.goodsIds",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "仓库" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "billType marR5",
                                attrs: {
                                  placeholder: "请选择",
                                  filterable: "",
                                  clearable: "",
                                  multiple: "",
                                  "collapse-tags": "",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.params.storeIds,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.params, "storeIds", $$v)
                                  },
                                  expression: "params.storeIds",
                                },
                              },
                              _vm._l(_vm.storeData, function (item) {
                                return _c("el-option", {
                                  key: item.storeId,
                                  attrs: {
                                    label: item.storeName,
                                    value: item.storeId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c("allTable", {
            attrs: {
              tableData: _vm.tableData,
              columnLabel: _vm.columnLabel,
              columnWidth: _vm.columnWidth,
              firstLabel: _vm.firstLabel,
              NoLabel: _vm.NoLabel,
              secondLevelOneLabel: _vm.secondLevelOneLabel,
              secondLevelTwoLabel: _vm.secondLevelTwoLabel,
            },
            on: { getRowData: _vm.getGoodsData },
          }),
          _c("bottomPage", {
            attrs: {
              getList: _vm.getList,
              totalCom: _vm.total,
              isSelectAll: false,
            },
            model: {
              value: _vm.queryParams,
              callback: function ($$v) {
                _vm.queryParams = $$v
              },
              expression: "queryParams",
            },
          }),
          _c("stockQueryDetail", {
            attrs: {
              openStockQuery: _vm.openStockQuery,
              goodsDetailData: _vm.goodsDetailData,
            },
            on: {
              "update:openStockQuery": function ($event) {
                _vm.openStockQuery = $event
              },
              "update:open-stock-query": function ($event) {
                _vm.openStockQuery = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }