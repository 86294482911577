<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { moveTotalListAPI, summaryByStore, summaryByStoreGoodsCategory, sumTransferSummary } from '@/api/store/report' //仓库报表
// const typesList = ['100201','110102','110103','120102','120103','130102','130103','130104','130105','130106','130107','130108','140303','140304','140305','140306','140307','140308','140310','140311','170202','170203','170205','100201',]
const typesList = ['140305','140306','130104','130105','130106', '170407', '170406', '170307', '170306']
export default {
  name: 'jxcList',
  components: { TablePage },
  data () {
    return {
      options: {
        // mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        
        body: {},
        listNo: true, // 序号
        title: '单据信息',
        labelWidth: "80",
        check: [], // 选中数据
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: "filters",
            tip: "全部/商品编号/商品名称/商品类别编号/商品类别名称/备注",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编号" },
              { filter: "goodsNames", label: "商品名称" },
              { filter: "categoryNos", label: "商品类别编号" },
              { filter: "categoryNames", label: "商品类别名称" },
            ],
          },
          this.$select({
            key: "billTypeList",
            option: { seniorSearch: true, option: { multiple: true, dataKey:(res)=> res.data.filter(x=> typesList.includes(x.dictValue) )} },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listInStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listOutStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        tabsColumns: [
          {
            title: "仓库调拨汇总表",
            exportOption: {
              exportApi: 'exportTransferSummary',
              exportName: '仓库调拨汇总表',
              timeout: 60*5*1000
            },
            getListApi: moveTotalListAPI,
            getSummaryApi: sumTransferSummary,
            type: "goods",
            columns: [
              {
                prop: 'billTypeName',
                label: '单据类型',
                minWidth: 120
              },
              {
                prop: 'outStoreNo',
                label: '调出仓库编号',
                minWidth: 150,
              },
              {
                prop: 'outStoreName',
                label: '调出仓库',
                minWidth: 120
              },
              {
                prop: 'inStoreNo',
                label: '调入仓库编号',
                minWidth: 160
              },
              {
                prop: 'inStoreName',
                label: '调入仓库',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '商品类别',
                minWidth: 150
              },
              {
                prop: 'goodsNo',
                label: '商品编码',
                minWidth: 150
              },
              {
                prop: 'goodsName',
                label: '商品名称',
                minWidth: 150
              },
              {
                prop: 'barcode',
                label: '商品条码',
                minWidth: 150
              },
              {
                prop: 'goodsSpec',
                label: '规格',
                minWidth: 150
              },
              {
                prop: 'unitName',
                label: '单位',
                minWidth: 150
              },
              {
                prop: 'unitPriceTaxMoney',
                label: '调拨金额',
                minWidth: 150
              },
              {
                prop: 'moveOutQty',
                label: '调出数量',
                minWidth: 150
              },
              {
                prop: 'moveOutPurMoney',
                label: '调出进价金额',
                minWidth: 150
              },
              {
                prop: 'moveOutSaleMoney',
                label: '调出售价金额',
                minWidth: 150
              },
              {
                prop: 'moveInQty',
                label: '调入数量',
                minWidth: 150
              },
              {
                prop: 'moveInPurMoney',
                label: '调入进价金额',
                minWidth: 150
              },
              {
                prop: 'moveInSaleMoney',
                label: '调入售价金额',
                minWidth: 150
              },
            ],
            summary: ["unitPriceTaxMoney", "moveOutQty", "moveOutPurMoney", "moveOutSaleMoney", "moveInQty", "moveInPurMoney", "moveInSaleMoney"],
          },
          {
            title: "仓库+类别调拨汇总",
            exportOption: {
              exportApi: 'exportSummaryByStoreGoodsCategory',
              exportName: '仓库+类别调拨汇总',
              timeout: 60*5*1000
            },
            getListApi: summaryByStoreGoodsCategory,
            getSummaryApi: sumTransferSummary,
            type: "goods",
            columns: [
              {
                prop: 'billTypeName',
                label: '单据类型',
                minWidth: 120
              },
              {
                prop: 'outStoreNo',
                label: '调出仓库编号',
                minWidth: 150,
              },
              {
                prop: 'outStoreName',
                label: '调出仓库',
                minWidth: 120
              },
              {
                prop: 'inStoreNo',
                label: '调入仓库编号',
                minWidth: 160
              },
              {
                prop: 'inStoreName',
                label: '调入仓库',
                minWidth: 120
              },
              {
                prop: 'categoryName',
                label: '商品类别',
                minWidth: 150
              },
              // {
              //   prop: 'goodsNo',
              //   label: '商品编码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsName',
              //   label: '商品名称',
              //   minWidth: 150
              // },
              // {
              //   prop: 'barcode',
              //   label: '商品条码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsSpec',
              //   label: '规格',
              //   minWidth: 150
              // },
              // {
              //   prop: 'unitName',
              //   label: '单位',
              //   minWidth: 150
              // },
              {
                prop: 'unitPriceTaxMoney',
                label: '调拨金额',
                minWidth: 150
              },
              {
                prop: 'moveOutQty',
                label: '调出数量',
                minWidth: 150
              },
              {
                prop: 'moveOutPurMoney',
                label: '调出进价金额',
                minWidth: 150
              },
              {
                prop: 'moveOutSaleMoney',
                label: '调出售价金额',
                minWidth: 150
              },
              {
                prop: 'moveInQty',
                label: '调入数量',
                minWidth: 150
              },
              {
                prop: 'moveInPurMoney',
                label: '调入进价金额',
                minWidth: 150
              },
              {
                prop: 'moveInSaleMoney',
                label: '调入售价金额',
                minWidth: 150
              },
            ],
            summary: ["unitPriceTaxMoney", "moveOutQty", "moveOutPurMoney", "moveOutSaleMoney", "moveInQty", "moveInPurMoney", "moveInSaleMoney"],
          },
          {
            title: "按仓库汇总",
            exportOption: {
              exportApi: 'exportSummaryByStore',
              exportName: '按仓库汇总',
              timeout: 60*5*1000
            },
            getListApi: summaryByStore,
            getSummaryApi: sumTransferSummary,
            type: "goods",
            columns: [
              {
                prop: 'billTypeName',
                label: '单据类型',
                minWidth: 120
              },
              {
                prop: 'outStoreNo',
                label: '调出仓库编号',
                minWidth: 150,
              },
              {
                prop: 'outStoreName',
                label: '调出仓库',
                minWidth: 120
              },
              {
                prop: 'inStoreNo',
                label: '调入仓库编号',
                minWidth: 160
              },
              {
                prop: 'inStoreName',
                label: '调入仓库',
                minWidth: 120
              },
              // {
              //   prop: 'categoryName',
              //   label: '商品类别',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsNo',
              //   label: '商品编码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsName',
              //   label: '商品名称',
              //   minWidth: 150
              // },
              // {
              //   prop: 'barcode',
              //   label: '商品条码',
              //   minWidth: 150
              // },
              // {
              //   prop: 'goodsSpec',
              //   label: '规格',
              //   minWidth: 150
              // },
              // {
              //   prop: 'unitName',
              //   label: '单位',
              //   minWidth: 150
              // },
              {
                prop: 'unitPriceTaxMoney',
                label: '调拨金额',
                minWidth: 150
              },
              {
                prop: 'moveOutQty',
                label: '调出数量',
                minWidth: 150
              },
              {
                prop: 'moveOutPurMoney',
                label: '调出进价金额',
                minWidth: 150
              },
              {
                prop: 'moveOutSaleMoney',
                label: '调出售价金额',
                minWidth: 150
              },
              {
                prop: 'moveInQty',
                label: '调入数量',
                minWidth: 150
              },
              {
                prop: 'moveInPurMoney',
                label: '调入进价金额',
                minWidth: 150
              },
              {
                prop: 'moveInSaleMoney',
                label: '调入售价金额',
                minWidth: 150
              },
            ],
            summary: ["unitPriceTaxMoney", "moveOutQty", "moveOutPurMoney", "moveOutSaleMoney", "moveInQty", "moveInPurMoney", "moveInSaleMoney"],
          },

          
        ],
      }
    }
  },
  methods: {
    seniorSearch () {},
  }
}
</script>
<style lang="scss" scoped></style>
