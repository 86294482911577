var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stock-query-detail" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "dialogDrag",
              rawName: "v-dialogDrag",
              value: true,
              expression: "true",
            },
          ],
          attrs: {
            title: "商品库存明细",
            visible: _vm.openStockQuery,
            width: "80%",
            "before-close": _vm.handleClose,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.openStockQuery = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "TopRegion" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-width": "80px",
                    model: _vm.goodsDetailData.mainReport,
                  },
                },
                _vm._l(_vm.TopLabel, function (item) {
                  return _c(
                    "el-form-item",
                    {
                      key: item.label,
                      attrs: { label: item.label, prop: item.prop },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.goodsDetailData.mainReport["" + item.prop],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.goodsDetailData.mainReport,
                              "" + item.prop,
                              $$v
                            )
                          },
                          expression:
                            "goodsDetailData.mainReport[`${item.prop}`]",
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttom" },
            [
              _c("allTable", {
                attrs: {
                  tableData: _vm.goodsDetailData.details,
                  columnLabel: _vm.columnLabel,
                  columnWidth: _vm.columnWidth,
                  firstLabel: _vm.firstLabel,
                  NoLabel: _vm.NoLabel,
                  tableHeight: _vm.tableHeight,
                },
                on: { getRowData: _vm.getBillData },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }