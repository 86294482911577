<template>
  <StoreMoveDiff />
</template>

<script>
import StoreMoveDiff from './components'
export default {
  name: 'storeMoveDiff',
  components: { StoreMoveDiff }
}
</script>
