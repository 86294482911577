var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "cardTitleCom",
        { staticClass: "marB10", attrs: { "card-title": "商品列表" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("div", { staticClass: "marB10 x-b" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            disabled: _vm.multiple,
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getDelete(_vm.ids)
                            },
                          },
                        },
                        [_vm._v("删除 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.submitForm },
                        },
                        [_vm._v("保存 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.test($event)
                            },
                          },
                        },
                        [_vm._v("刷新 ")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.formData,
                      rules: _vm.rules,
                      "label-width": "10px",
                    },
                  },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadingTable,
                            expression: "loadingTable",
                          },
                        ],
                        ref: "multipleTable",
                        attrs: {
                          "tooltip-effect": "dark",
                          data: _vm.formData.tableDataAvailable,
                          border: "",
                          "max-height": "680",
                          height: "680",
                        },
                        on: {
                          "row-click": _vm.handleRowClick,
                          "selection-change": _vm.handleSelectionChange,
                          "cell-mouse-enter": _vm.cellMouseEnter,
                          "cell-mouse-leave": _vm.cellMouseLeave,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            type: "selection",
                            width: "50",
                            align: "center",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabel,
                            width: "100",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowAvailable(
                                              "push",
                                              scope.$index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.rowAvailable(
                                              "del",
                                              scope.$index,
                                              scope.row.goodsId,
                                              scope.row.totalSafeStockId
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品编号",
                            align: "center",
                            prop: "goodsNo",
                            width: "160",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { "margin-bottom": "5px" },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.keyup(
                                            "goodsNo",
                                            $event,
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.goodsNo,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "goodsNo", $$v)
                                        },
                                        expression: "scope.row.goodsNo",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-more more marT10",
                                        attrs: { slot: "suffix" },
                                        on: {
                                          click: function ($event) {
                                            _vm.OpenGoods = true
                                          },
                                        },
                                        slot: "suffix",
                                      }),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": "",
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                            width: "220",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": "",
                            label: "条码",
                            align: "center",
                            prop: "barcode",
                            width: "180",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": "",
                            label: "规格",
                            align: "center",
                            prop: "goodsSpec",
                            width: "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            "show-overflow-tooltip": "",
                            label: "基本单位",
                            align: "center",
                            prop: "unitName",
                            width: "160",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "最低库存",
                            align: "center",
                            prop: "safeMinQty",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableDataAvailable[" +
                                          scope.$index +
                                          "].safeMinQty",
                                        rules:
                                          _vm.rules[
                                            "tableDataAvailable.safeMinQty"
                                          ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "number",
                                          oninput:
                                            "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-]\\d*\\.?\\d{0,8})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.focusIndex(scope.$index)
                                          },
                                        },
                                        model: {
                                          value: scope.row.safeMinQty,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "safeMinQty",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.safeMinQty",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "最高库存",
                            align: "center",
                            prop: "safeMaxQty",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableDataAvailable[" +
                                          scope.$index +
                                          "].safeMaxQty",
                                        rules:
                                          _vm.rules[
                                            "tableDataAvailable.safeMaxQty"
                                          ],
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "number",
                                          oninput:
                                            "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-]\\d*\\.?\\d{0,8})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.focusIndex(scope.$index)
                                          },
                                        },
                                        model: {
                                          value: scope.row.safeMaxQty,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "safeMaxQty",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.safeMaxQty",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("pagination", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.total > 0,
                      expression: "total > 0",
                    },
                  ],
                  attrs: {
                    total: _vm.total,
                    page: _vm.queryParams.pageNum,
                    limit: _vm.queryParams.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.queryParams, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.queryParams, "pageSize", $event)
                    },
                    pagination: _vm.getList,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("SelectGoods", {
        attrs: { OpenGoods: _vm.OpenGoods, "query-code": _vm.queryCode },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.OpenGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.OpenGoods = $event
          },
          getGoodS: _vm.getGoodS,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }