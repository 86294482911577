var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "check-detail" },
    [
      _c("StoreDetail", {
        ref: "storeDetail",
        attrs: { billType: _vm.billType },
        scopedSlots: _vm._u([
          {
            key: "selectTwoArea",
            fn: function (scoped) {
              return [
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "调整方式", prop: "billPostType" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择调整方式",
                              clearable: "",
                              disabled: scoped.disabled,
                              size: "mini",
                            },
                            model: {
                              value: scoped.form.billPostType,
                              callback: function ($$v) {
                                _vm.$set(scoped.form, "billPostType", $$v)
                              },
                              expression: "scoped.form.billPostType",
                            },
                          },
                          _vm._l(_vm.dict.type.in_out_store, function (dict) {
                            return _c("el-option", {
                              key: dict.value,
                              attrs: { label: dict.label, value: dict.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "searchItem div-number-item" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "调整原因", prop: "scrapTypeId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择调整原因",
                              clearable: "",
                              disabled: scoped.disabled,
                              size: "mini",
                            },
                            model: {
                              value: scoped.form.scrapTypeId,
                              callback: function ($$v) {
                                _vm.$set(scoped.form, "scrapTypeId", $$v)
                              },
                              expression: "scoped.form.scrapTypeId",
                            },
                          },
                          _vm._l(_vm.dict.type.adjust_reason, function (dict) {
                            return _c("el-option", {
                              key: dict.value,
                              attrs: { label: dict.label, value: dict.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("selectStore", {
        attrs: { OpenStore: _vm.OpenStore },
        on: {
          "update:OpenStore": function ($event) {
            _vm.OpenStore = $event
          },
          "update:open-store": function ($event) {
            _vm.OpenStore = $event
          },
          getStore: _vm.getStore,
        },
      }),
      _c("storeAdd", {
        attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
        on: {
          "update:OpenStoreAdd": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          "update:open-store-add": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          getStore: _vm.getStore,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }