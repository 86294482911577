var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "move-detail" },
    [
      _c(
        "StoreDetail",
        {
          ref: "storeDetail",
          attrs: { billType: _vm.billType, orderInfo: _vm.orderInfo },
        },
        [
          _c(
            "div",
            [
              _c("selectStore", {
                attrs: { OpenStore: _vm.OpenStore },
                on: {
                  "update:OpenStore": function ($event) {
                    _vm.OpenStore = $event
                  },
                  "update:open-store": function ($event) {
                    _vm.OpenStore = $event
                  },
                  getStore: _vm.getStore,
                },
              }),
              _c("storeAdd", {
                attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
                on: {
                  "update:OpenStoreAdd": function ($event) {
                    _vm.OpenStoreAdd = $event
                  },
                  "update:open-store-add": function ($event) {
                    _vm.OpenStoreAdd = $event
                  },
                  getStore: _vm.getStore,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }