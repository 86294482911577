<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import { listBookStatisticsByStoreGoodsDay, sumListBookStatisticsByStoreGoodsDay, listBookStatisticsByStoreGoods, sumListBookStatisticsByStoreGoods } from '@/api/system/store' //仓库

export default {
  name: "stockDayQuery",
  components: { TablePage },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "销售订单汇总表",
        labelWidth: "80",
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBookDate: a, endBookDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部、商品编号、商品名称、条码、商品类别名称、商品类别编号、仓库名称、仓库编号",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "goodsNos", label: "商品编号", group: 'goodsQuery' },
              { filter: "goodsNames", label: "商品名称", group: 'goodsQuery' },
              { filter: "barcodes", label: "条码", group: 'goodsQuery' },
              { filter: "categoryNames", label: "商品类别名称", group: 'categoryQuery' },
              { filter: "categoryNos", label: "商品类别编号", group: 'categoryQuery' },
              { filter: "storeNames", label: "仓库名称", group: 'storeQuery' },
              { filter: "storeNos", label: "仓库编号", group: 'storeQuery' },
            ],
          },
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabsColumns: [
          {
            title: "每日库存明细查询",
            // defaultBody: { searchType: "goods" },
            type: "goods",
            exportOption: {
              show: true,
              exportApi: 'exportListBookStatisticsByStoreGoodsDay',
              exportName: '每日库存明细查询',
              timeout: 60*5*1000
            },
            getListApi: listBookStatisticsByStoreGoodsDay,
            getSummaryApi: sumListBookStatisticsByStoreGoodsDay,
            columns: [
              {
                prop: "dayDate",
                label: "日期",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.goodsNo 
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.goodsName 
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.barcode 
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
                formatter :(v, row) => row.goodsCategoryInfo.categoryName 
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.goodsSpec 
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.unitName 
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
                formatter :(v, row) => row.storeInfo.storeName 
              },
              {
                prop: "firstQty",
                label: "期初数",
                minWidth: 150,
              },
              {
                prop: "firstMoney",
                label: "期初金额",
                minWidth: 150,
              },
              {
                prop: "inQty",
                label: "入库数",
                minWidth: 150,
              },
              {
                prop: "inMoney",
                label: "入库金额",
                minWidth: 150,
              },
              {
                prop: "outQty",
                label: "出库数",
                minWidth: 150,
              },
              {
                prop: "outMoney",
                label: "出库金额",
                minWidth: 150,
              },
              {
                prop: "finalQty",
                label: "期末数",
                minWidth: 150,
              },
              {
                prop: "finalMoney",
                label: "期末金额",
                minWidth: 150,
              },
            ],
            summary: ["finalMoney", "finalQty", "outMoney", "outQty", "inMoney", "inQty", "firstMoney", "firstQty"],
          },
          {
            title: "每日库存汇总查询",
            // defaultBody: { searchType: "goods" },
            type: "goods",
            exportOption: {
              show: true,
              exportApi: 'exportListBookStatisticsByStoreGoods',
              exportName: '每日库存汇总查询',
              timeout: 60*5*1000
            },
            getListApi: listBookStatisticsByStoreGoods,
            getSummaryApi: sumListBookStatisticsByStoreGoods,
            columns: [
              {
                prop: "goodsNo",
                label: "商品编码",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.goodsNo 
              },
              {
                prop: "goodsName",
                label: "商品名称",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.goodsName 
              },
              {
                prop: "barcode",
                label: "条码",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.barcode 
              },
              {
                prop: "categoryName",
                label: "商品类别",
                minWidth: 150,
                formatter :(v, row) => row.goodsCategoryInfo.categoryName 
              },
              {
                prop: "goodsSpec",
                label: "规格",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.goodsSpec 
              },
              {
                prop: "unitName",
                label: "基本单位",
                minWidth: 150,
                formatter :(v, row) => row.goodsInfo.unitName 
              },
              {
                prop: "storeName",
                label: "仓库",
                minWidth: 150,
                formatter :(v, row) => row.storeInfo.storeName 
              },
              {
                prop: "firstQty",
                label: "期初数",
                minWidth: 150,
              },
              {
                prop: "firstMoney",
                label: "期初金额",
                minWidth: 150,
              },
              {
                prop: "inQty",
                label: "本期入库数",
                minWidth: 150,
              },
              {
                prop: "inMoney",
                label: "本期入库金额",
                minWidth: 150,
              },
              {
                prop: "outQty",
                label: "本期出库数",
                minWidth: 150,
              },
              {
                prop: "outMoney",
                label: "本期出库金额",
                minWidth: 150,
              },
              {
                prop: "finalQty",
                label: "期末数",
                minWidth: 150,
              },
              {
                prop: "finalMoney",
                label: "期末金额",
                minWidth: 150,
              },
            ],
            summary: ["finalMoney", "finalQty", "outMoney", "outQty", "inMoney", "inQty", "firstMoney", "firstQty"],
          },
        ],
      },
    };
  },
};
</script>
