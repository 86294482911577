var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "check-detail" },
    [
      _c("StoreDetail", {
        ref: "storeDetail",
        attrs: { billType: _vm.billType },
        scopedSlots: _vm._u([
          {
            key: "selectOneArea",
            fn: function (scoped) {
              return [
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘亏仓库", prop: "outStoreId" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              filterable: "",
                              clearable: "",
                              disabled: scoped.disabled,
                              size: "mini",
                            },
                            model: {
                              value: scoped.form.outStoreId,
                              callback: function ($$v) {
                                _vm.$set(scoped.form, "outStoreId", $$v)
                              },
                              expression: "scoped.form.outStoreId",
                            },
                          },
                          _vm._l(scoped.storeData, function (item) {
                            return _c("el-option", {
                              key: item.storeId,
                              attrs: {
                                label: item.storeName,
                                value: item.storeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "searchItem" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "盘点部门" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              filterable: "",
                              clearable: "",
                              disabled: scoped.disabled,
                              size: "mini",
                            },
                            model: {
                              value: scoped.form.deptId,
                              callback: function ($$v) {
                                _vm.$set(scoped.form, "deptId", $$v)
                              },
                              expression: "scoped.form.deptId",
                            },
                          },
                          _vm._l(_vm.deptData, function (item) {
                            return _c("el-option", {
                              key: item.deptId,
                              attrs: {
                                label: item.deptName,
                                value: item.deptId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("selectStore", {
        attrs: { OpenStore: _vm.OpenStore },
        on: {
          "update:OpenStore": function ($event) {
            _vm.OpenStore = $event
          },
          "update:open-store": function ($event) {
            _vm.OpenStore = $event
          },
          getStore: _vm.getStore,
        },
      }),
      _c("storeAdd", {
        attrs: { OpenStoreAdd: _vm.OpenStoreAdd },
        on: {
          "update:OpenStoreAdd": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          "update:open-store-add": function ($event) {
            _vm.OpenStoreAdd = $event
          },
          getStore: _vm.getStore,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }