<template>
    <div class="wrap" v-loading="loading" id="wrap" ref="wrap">
        <cardTitleCom cardTitle="组装拆卸关系">
            <template slot="cardContent">
                <div style="padding: 10px 10px 10px 20px;">
                    <seniorSearch
                        :isBillDate="false"
                        filterTimeStr="thisMonth"
                        v-model="queryParams"
                        :getList="getList"
                        :dropDownList="dropDownList"
                        :isSearchInput="true"
                        isSearchInputTitle="单据编号、订货仓库编号、名称、调出仓库编号、名称"
                        :isAdvancedSearch="false"
                    />
                </div>
            </template>
        </cardTitleCom>
        <el-form ref="form" :model="options" :rules="rules" label-width="106px" class="content">
            <cardTitleCom cardTitle="商品信息" class="left flex1 mr20">
                <template slot="cardContent">
                    <div class="table">
                        <!-- 可编辑表格 -->
                        <EditTable :options="options" @handleEvent="handleEvent" />
                        <bottomPage
                            v-model="queryParams"
                            :getList="getList"
                            :idsCom="ids"
                            :totalCom="total"
                            @selectAllCom="selectAll"
                        />
                    </div>
                </template>
            </cardTitleCom>
            <cardTitleCom cardTitle="商品明细信息" class="right flex1">
                <template slot="cardContent">
                    <div class="table">
                        <!-- 可编辑表格 -->
                        <EditTable :options="sourceOptions" @handleEvent="handleEvent" />
                    </div>
                </template>
            </cardTitleCom>
        </el-form>
        <!-- 弹窗 -->
        <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
    </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import Dialog from '@/components/Dialog'
import { listMainGoods, listSubGoods } from '@/api/combinStrip/index'

// import { getShouldPaymentPurchaseFunds, addPrePaymentRefundBill, modifyPrePaymentRefundBill, getPrePaymentRefundBillByBillId } from '@/api/combinStrip/index'
import { uniqWith } from 'lodash' //去重
import { limitInputlength, fcount } from '@/utils'

export default {
    name: 'FillDetail',
    components: {
        EditTable,
        cardTitleCom,
        Dialog,
        seniorSearch
    },
    watch: {
      // 'options.billStatus': {
      //     handler (val) {
      //         console.log(val, 'val')
      //         this.disableOperate = ['2', '3', '4', 2, 3, 4].includes(val)
      //         this.isShowAudit = ['', 4, '4'].includes(val)

      //         this.options.columns[0].disabled = this.disableOperate
      //         this.options.columns[1].disabled = this.disableOperate
      //         this.options.columns[2].disabled = this.disableOperate
      //         this.options.columns[3].disabled = this.disableOperate
      //         this.sourceOptions.columns[7].disabled = this.disableOperate
      //         this.sourceOptions.columns[8].disabled = this.disableOperate
      //         this.options.status = this.disableOperate
      //     },
      //     immediate: true
      // },
    },
    data() {
        return {
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 15
            },
            dropDownList: [
                { parameter: 'query', name: '全部' },
                { parameter: 'billNos', name: '单据编号' },
                { parameter: 'orderStoreNos', name: '订货仓库编号' },
                { parameter: 'orderStoreNames', name: '订货仓库名称' },
                { parameter: 'produceDeptNos', name: '生产部门编号' },
                { parameter: 'produceDeptNames', name: '生产工厂' }
            ],
            isAdd: '',
            customerData: [],
            deptData: [],
            employeeData: [],
            loading: false, //遮罩层
            //表格配置
            options: {
                tableIndex: 0,
                retainColumn: true,
                curListIndex: -1, //当前表格行
                mutiSelect: false, //是否多选
                list: [{}],
                curListIndex: -1, //当前表格下标
                tableCellLabel: '序号',
                tableHeight: 600, //表格高度
                status: undefined, //单据状态
                // isNoAddBtn: true, //不显示表格增加
                hideTotal: true,
                columns: [
                    {
                        prop: 'payMoney',
                        label: '商品编码',
                        width: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                        // rules: true,
                        // isMinus: true,
                    },
                    {
                        prop: 'goodsNo',
                        label: '商品名称',
                        width: 140,
                        type: 'remoteSelect',
                        click: 'clickAccount',
                        Width: 200,
                        align: 'center',
                        option: this.$select({
                            key: 'listGoods',
                            option: {
                                option: {
                                    noEcho: true,
                                    // label: 'goodsName',
                                    // value: 'goodsNo',
                                    // modal: 'goodsNo',
                                    //  goodsTypes: [1, 2] 
                                    remoteBody: {},
                                    change: (propValue, row) => {
                                        console.log(propValue, row)
                                        return
                                        let tableIndex = this.options.tableIndex
                                        if (row && this.options.tableIndex != undefined) {
                                            this.$set(this.options.list[tableIndex], 'goodsId', row.goodsId)
                                            this.$set(this.options.list[tableIndex], 'goodsNo', row.goodsNo)
                                            this.$set(this.options.list[tableIndex], 'goodsName', row.goodsName)
                                        }
                                    },
                                    tableChange: (propValue, row) => {
                                        console.log('getSelectData11111', row)
                                        return
                                        this.handleEvent('getSelectData', row)
                                    },
                                    buttons: [
                                        {
                                            type: 'more',
                                            option: {
                                                title: '选择账户',
                                                width: 1250,
                                                type: 'TreeAndTable',
                                                formData: {
                                                    ...this.$dialog({ key: 'goods' }),
                                                    table: {
                                                        ...this.$dialog({ key: 'goods' }).table,
                                                        mutiSelect: true
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        }).option,
                        disabled: false,
                        // rules: true
                    },
                    {
                        prop: 'payMoney',
                        label: '商品条码',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                        // rules: true,
                        // isMinus: true,
                    },
                    {
                        prop: 'payMoney',
                        label: '规格',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                    },
                    {
                        prop: 'payMoney',
                        label: '单位',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                    },
                // {
                //     prop: 'remark',
                //     label: '备注',
                //     minWidth: 120,
                //     align: 'center',
                //     type: 'input',
                //     disabled: false
                // }
                ],
            //   summary: ['payMoney'],
            },
            sourceOptions: {
                curListIndex: -1, //当前表格行
                mutiSelect: false, //是否多选
                list: [],
                curListIndex: -1, //当前表格下标
                tableCellLabel: '序号',
                tableHeight: 600, //表格高度
                status: undefined, //单据状态
                // isNoAddBtn: true, //不显示表格增加
                hideTotal: true,
                retainColumn: true,
                columns: [
                    {
                        prop: 'payMoney',
                        label: '商品编码',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                        // rules: true,
                        // isMinus: true,
                    },
                    {
                        prop: 'goodsNo',
                        label: '商品名称',
                        type: 'remoteSelect',
                        click: 'clickAccount',
                        Width: 200,
                        align: 'center',
                        option: this.$select({
                            key: 'listGoods',
                            option: {
                                option: {
                                    remoteBody: {},
                                    change: (propValue, row) => {
                                        console.log(propValue, row)
                                        let tableIndex = this.options.tableIndex
                                        if (row && this.options.tableIndex != undefined) {
                                            this.$set(this.options.list[tableIndex], 'goodsId', row.goodsId)
                                            this.$set(this.options.list[tableIndex], 'goodsNo', row.goodsNo)
                                            this.$set(this.options.list[tableIndex], 'goodsName', row.goodsName)
                                        }
                                    },
                                    tableChange: (propValue, row) => {
                                        console.log('getSelectData22222')
                                        this.handleEvent('getSelectData', row)
                                    },
                                    buttons: [
                                        {
                                            type: 'more',
                                            option: {
                                                title: '选择账户',
                                                width: 1250,
                                                type: 'TreeAndTable',
                                                formData: {
                                                    ...this.$dialog({ key: 'account' }),
                                                    table: {
                                                        ...this.$dialog({ key: 'account' }).table,
                                                        mutiSelect: true
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        }).option,
                        disabled: false,
                        // rules: true
                    },
                    {
                        prop: 'payMoney',
                        label: '商品条码',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                        // rules: true,
                        // isMinus: true,
                    },
                    {
                        prop: 'payMoney',
                        label: '规格',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                    },
                    {
                        prop: 'payMoney',
                        label: '单位',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false,
                    },
                    {
                        prop: 'remark',
                        label: '数量',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false
                    },
                    {
                        prop: 'remark',
                        label: '进价',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false
                    },
                    {
                        prop: 'remark',
                        label: '售价',
                        minWidth: 120,
                        align: 'center',
                        type: 'input',
                        disabled: false
                    },
                ],
                summary: ['billTaxMoney', 'settleMoney', 'unverified', 'nowWriteOffMoney'],
            },
            //弹窗配置
            dialogOptions: {
                title: '选择',
                width: 1250,
                show: false,
                type: 'TreeAndTable'
            },
            rules: {
                billNo: [
                    {
                        required: true,
                        message: '请输入方案编号',
                        trigger: ['blur', 'change']
                    }
                ],
                billDate: [
                    { required: true, message: '请输入制单日期', trigger: 'blur' }
                ]
            },
            ceshi: undefined,
            pageH: 0, //页面高度
            btnsH: 0, //搜索框高度
            basicH: 0, //基础框高度
            paginationH: 50 //分页高度
        }
    },
    activated() {
      // this.getDetail()
    },
    mounted() {
      // this.getDetail()
    },
    beforeDestroy() {},
    created() { },
    computed: {},
    methods: {
        getList () {

            return
            listMainGoods(form).then((res) => {
                console.log(res)
                // this.options = {
                //     ...this.options,
                //     billStatus: res.data.billStatus,
                //     billId: res.data.billId
                // }
                // let obj = this.filterDataFun(res.data.preRpDetails, res.data.billSubType == 1?  res.data.arDetails : res.data.apDetails)
                // console.log(obj, 'obj')
                // this.options.list = obj.optionsArr
                // this.sourceOptions.list = obj.sourceArr
                // this.$message.success('新增成功')
                // //新增
                // if (isAdd) {
                //     this.reset()
                //     this.getBillno()
                // }
                // this.loading = false
            }).catch((err) => {
                console.log(err, 'err')
                this.loading = false
            })
        },
        //保留小数位
        limitInputlength,
        filterDataFun (list) {
            let arrlist = list.map((item)=> (
                {
                    ...item,
                    billDate: item.sourceBillDateStr,
                    billTypeName: item.sourceBillTypeName,
                    billNo: item.sourceBillNo,
                    remark: item.remark,
                    billRemark: item.sourceBillRemark,
                    billTaxMoney: item.sourceBillMoney,
                    settleMoney: item.sourceBillWrittenOffMoney,
                    unverified:  fcount([item.sourceBillMoney , item.sourceBillWrittenOffMoney],'-'),
                    nowWriteOffMoney: item.nowWriteOffMoney,
                }
            ))
            return arrlist
        },
      //获取详情
        async getDetail() {
            this.reset()
            if (this.$route.query.type === 'Update' && this.$route.query.billId) {
                this.loading = true
                try {
                    const { data } = await getPrePaymentRefundBillByBillId({ billId: this.$route.query.billId })
                    //方案赋值
                    this.options = {
                        ...this.options,
                        ...data,
                        list: []
                    }
                    this.options.list = data.payDetails
                    let arrlist = data.apDetails.map((item) => (
                        {
                            ...item,
                            billDate: item.sourceBillDateStr,
                            billTypeName: item.sourceBillTypeName,
                            billNo: item.sourceBillNo,
                            remark: item.remark,
                            billRemark: item.sourceBillRemark,
                            billTaxMoney: item.sourceBillMoney,
                            settleMoney: item.sourceBillWrittenOffMoney,
                            unverified: fcount([item.sourceBillMoney , item.sourceBillWrittenOffMoney],'-'),
                            nowWriteOffMoney: item.nowWriteOffMoney,
                        }
                    ))
                    if (this.options.fileItems?.length > 0) {
                        this.options.fileItems = this.options.fileItems.map((item)=> (
                            {
                                ...item,
                                name: item.fileName,
                                url: item.accessUrl,
                            }
                        ))
                    }
                    this.sourceOptions.list = arrlist
                    getShouldPaymentPurchaseFunds({ partnerId: this.options.partnerId }).then(res => {
                        this.options.accountsBalance = res.data.shouldSettleMoney
                    })
                } catch (err) { }
                this.loading = false
            } else {

            }
        },
        async handleEvent(type, row) {
            console.log(type, row, 'row, type')
            //获取元素在数组的下标
            let contains = function (list, row) {
                var i = list.length
                while (i--) {
                    if (list[i] === row) {
                        return i
                    }
                }
                return false
            }
            switch (type) {
                case 'SourceDialogChange':
                    console.log(row, 'row');
                    this.dialogOptions.show = false
                    row.list = row.list.map((item) => (
                        {
                            ...item,
                            billTaxMoney: item.billRpMoney,
                            unverified: item.billRpMoney - item.settleMoney,
                            nowWriteOffMoney: item.billRpMoney - item.settleMoney,
                            sourceBillType: item.billType,
                            sourceBillId: item.billId,
                            sourceBillWrittenOffMoney: item.settleMoney,
                            remark: item.remark,
                            billRemark: item.billRemark,
                        }
                    ))
                    this.sourceOptions.list = [...this.sourceOptions.list, ...row.list]
                    this.sourceOptions.list = uniqWith(this.sourceOptions.list, (x, y) => x.sourceBillId== y.sourceBillId).filter(x => x.sourceBillId)
                    break;
                case 'clickAccount':
                    //保存当前表格行下标
                    this.options.curListIndex = contains(this.options.list, row)
                    this.dialogOptions.show = true
                    //弹窗配置
                    this.dialogOptions = {
                        title: '选择账户',
                        width: 1250,
                        show: true,
                        type: 'TreeAndTable',
                        formData: this.$dialog({ key: 'product' })
                    }
                    break
                case 'nowWriteOffMoney':
                    break
                case 'getSelectDataPay':
                case 'getSelectData':
                    if (!row) return
                    let { check } = row
                    if (check.length <= 0) return
                    if (type == 'getSelectData') {
                        let tableIndex = this.options.tableIndex
                        this.$set(this.options.list[tableIndex], 'bankAccountId', check[0].bankAccountId)
                        this.$set(this.options.list[tableIndex], 'bankAccountNo', check[0].bankAccountNo)
                        this.$set(this.options.list[tableIndex], 'bankAccountName', check[0].bankAccountName)
                    } else {
                        let tableIndex = this.options.tableIndex
                        this.$set(this.options.list[tableIndex], 'payModeId', check[0].payModeId)
                        this.$set(this.options.list[tableIndex], 'payModeName', check[0].payModeName)
                        this.$set(this.options.list[tableIndex], 'payModeNo', check[0].payModeNo)
                    }
                    break
                case 'tableIndex':
                    this.options.tableIndex = row
                    break
                default:
                    break
            }
        },
        async userSubmit(isAdd) {
            this.loading = true
            let form = {
                billNo: this.options.billNo, // 方案号
                billId: this.options.billId, // 方案ID
                partnerId: this.options.partnerId, // 方案ID
                deptId: this.options.deptId, // 方案ID
                saleEmployeeId: this.options.saleEmployeeId, // 方案ID
                billRemark: this.options.billRemark, // 方案ID
                discountMoney: this.options.discountMoney, // 方案ID
                billDate: this.options.billDate
                    ? this.options.billDate.split(' ')[0]
                    : '', // 方案时间
                payDetails: this.options.list,
                apDetails: this.sourceOptions.list,
                fileItems : this.options.fileItems,
            }
            if (form.billId) {
                modifyPrePaymentRefundBill(form).then((res) => {
                    this.options = {
                        ...this.options,
                        billStatus: res.data.billStatus
                    }
                    let arrlist = this.filterDataFun(res.data.apDetails)
                    this.sourceOptions.list = arrlist
                    this.$message.success('修改成功')
                    //新增
                    if (isAdd) {
                        this.reset()
                    }
                    this.loading = false
                }).catch((err) => {
                    console.log(err, 'err')
                    this.loading = false
                })

            } else {
                addPrePaymentRefundBill(form).then((res) => {
                    this.options = {
                        ...this.options,
                        billStatus: res.data.billStatus,
                        billId: res.data.billId
                    }
                    let arrlist = this.filterDataFun(res.data.apDetails)
                    this.sourceOptions.list = arrlist
                    this.$message.success('新增成功')
                    //新增
                    if (isAdd) {
                        this.reset()
                    }
                    this.loading = false
                }).catch((err) => {
                    console.log(err, 'err')
                    this.loading = false
                })
            }

        },
        // 提交保存
        async submitForm(isAdd) {
            this.isAdd = isAdd
            this.$refs['form'].validate(async valid => {
                if (valid) {
                    let sourceIndex = this.sourceOptions.list.findIndex(item => item.nowWriteOffMoney == '' || item.nowWriteOffMoney == undefined)
                    if (sourceIndex != '-1' || this.sourceOptions.list.length == 0) return this.$message.error('请先录入完整的源单信息')
                    // 优惠金额 = 本次核销金额 - (收款金额 + 手续费)
                    let payMoneyTotal = 0; // 本次收款统计
                    let nowWriteOffMoneyTotal = 0; // 本次核销金额统计
                    payMoneyTotal = this.options.list.reduce((prev, next) => { return fcount([prev, next.payMoney],'+')}, 0);
                    nowWriteOffMoneyTotal = this.sourceOptions.list.reduce((prev, next) => { return fcount([prev, next.nowWriteOffMoney],'+')}, 0);
                    let discountMoney = this.options.discountMoney ? this.options.discountMoney : 0

                    if (fcount([payMoneyTotal, discountMoney],'+') != Number(nowWriteOffMoneyTotal)) return this.$message.error('本次核销金额合计不等于退款总金额!')
                    this.userSubmit(isAdd)
                } else this.$message.error('请输入必填项!')
            })
        },
        //初始化数据
        reset() {
            //表格配置
            this.options = {
                ...this.options,
                billNo: undefined,
                billDate: undefined,
                partnerId: undefined,
                deptId: undefined,
                saleEmployeeId: undefined,
                accountsBalance: undefined, // 应收款余额 不传的
                discountMoney: undefined,
                billStatus: '',
                billRemark: '',
                fileItems: [],
            }
            this.options.list = [{}],
                this.sourceOptions.list = [],
                this.resetForm('form')
        },
    }
}
</script>

<style lang="scss" scoped>
.wrap {
    padding: 40px 10px 10px 10px;
    background-color: #eaeaea;
    height: calc(100vh - 84px);
    overflow-y: scroll;
    .content {
    display: flex;
    }
}
.flex1 {
    flex: 1;
}
.mr20 {
    margin-right: 20px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 250px;
}
.table {
    padding: 10px;
}
</style>
