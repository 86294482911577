<template>
  <div class="wrap">
    <cardTitleCom card-title="商品列表" class="marB10">
      <template slot="cardContent">
        <div class="navSearch">
          <div class="marB10 x-b">
            <div>
              <el-button
                type="danger"
                icon="el-icon-delete"
                :disabled="multiple"
                size="mini"
                @click="getDelete(ids)"
                >删除
              </el-button>
            </div>
            <div>
              <el-button type="primary" size="mini" @click="submitForm"
                >保存
              </el-button>

              <el-button
                icon="el-icon-refresh"
                size="mini"
                @click="test($event)"
                >刷新
              </el-button>
            </div>
          </div>
          <el-form ref="form" :model="formData" :rules="rules" label-width="10px">
            <el-table
              ref="multipleTable"
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="formData.tableDataAvailable"
              border
              max-height="680"
              height="680"
              @row-click="handleRowClick"
              @selection-change="handleSelectionChange"
              @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave"
            >
              <el-table-column type="selection" width="50" align="center" />

              <el-table-column align="center" :label="tableCellLabel" width="100">
                <template #default="scope">
                  <i
                    v-if="scope.row.hoverRow"
                    class="el-icon-circle-plus operatePush"
                    @click="rowAvailable('push', scope.$index)"
                  />
                  <i
                    v-if="scope.row.hoverRow"
                    class="el-icon-remove operateDel"
                    @click="
                      rowAvailable(
                        'del',
                        scope.$index,
                        scope.row.goodsId,
                        scope.row.totalSafeStockId
                      )
                    "
                  />
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>

              <el-table-column
                label="商品编号"
                align="center"
                prop="goodsNo"
                width="160"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="scope.row.goodsNo"
                    style="margin-bottom: 5px"
                    @keyup.enter.native="keyup('goodsNo', $event, scope.$index)"
                  >
                    <i
                      slot="suffix"
                      class="el-icon-more more marT10"
                      @click="OpenGoods = true"
                    />
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="商品名称"
                align="center"
                prop="goodsName"
                width="220"
              />

              <el-table-column
                show-overflow-tooltip
                label="条码"
                align="center"
                prop="barcode"
                width="180"
              />
              <el-table-column
                show-overflow-tooltip
                label="规格"
                align="center"
                prop="goodsSpec"
                width="160"
              />

              <el-table-column
                show-overflow-tooltip
                label="基本单位"
                align="center"
                prop="unitName"
                width="160"
              />

              <el-table-column label="最低库存" align="center" prop="safeMinQty"
                ><template slot-scope="scope">
                  <!-- :prop="scope.$index + '.unitId'" -->
                  <el-form-item
                    :prop="`tableDataAvailable[${scope.$index}].safeMinQty`" 
                    :rules="rules[`tableDataAvailable.safeMinQty`]"
                  >
                    <el-input
                      @focus="focusIndex(scope.$index)"
                      v-model="scope.row.safeMinQty"
                      type="number"
                      oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-]\d*\.?\d{0,8})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value" 
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="最高库存" align="center" prop="safeMaxQty"
                ><template slot-scope="scope">
                  <el-form-item
                    :prop="`tableDataAvailable[${scope.$index}].safeMaxQty`"
                    :rules="rules[`tableDataAvailable.safeMaxQty`]"
                  >
                    <el-input
                      @focus="focusIndex(scope.$index)"
                      v-model="scope.row.safeMaxQty"
                      type="number"
                      oninput="value=value.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^([0-9-]\d*\.?\d{0,8})?.*$/,'$1').replace(/^\./g, '').replace(/^0+(?!\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value" 
                    />
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
          <!-- 分页 -->
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
          />
        </div>
      </template>
    </cardTitleCom>
    <!-- 选择商品信息框 -->
    <SelectGoods
      :OpenGoods.sync="OpenGoods"
      :query-code="queryCode"
      @getGoodS="getGoodS"
    />
  </div>
</template>

<script>
import SelectGoods from '@/views/components/selectGoodsUp' // 选择商品
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  totalSaveOrUpdateAllAPI,
  totalSafeStockListAPI,
  totalSafeStockRemoveAPI
} from '@/api/store/alert/totalSafeStock' // 总仓库安全库存
import { number } from 'echarts/lib/export'
export default {
  name: 'TotalSafeStock',
  components: { bottomPage, cardTitleCom, seniorSearch, SelectGoods },
  data () {
    const safeMaxQtyFun = (rule, value, callback) => {
      if (this.listIndex == '') {
        callback();
      }
      let safeMinQty = this.formData.tableDataAvailable[this.listIndex].safeMinQty
      let safeMaxQty= this.formData.tableDataAvailable[this.listIndex].safeMaxQty
      if (safeMaxQty === '' || safeMaxQty === undefined || safeMaxQty === null) {
        callback(new Error('请输入最高库存'));
      } else {
        if (safeMinQty === '' || safeMinQty === undefined || safeMinQty === null) {
          callback();
        } else if (Number(safeMaxQty) <= Number(safeMinQty)) {
          callback(new Error('最高库存需大于最低库存'));
        }
      }
    };

    const safeMinQtyFun = (rule, value, callback) => {
      if (this.listIndex == '') {
        callback();
      }
      let safeMaxQty = this.formData.tableDataAvailable[this.listIndex].safeMaxQty
      let safeMinQty = this.formData.tableDataAvailable[this.listIndex].safeMinQty
      if (safeMinQty === '' || safeMinQty === undefined || safeMinQty === null) {
        callback(new Error('请输入最低库存'));
      } else {
        if (safeMaxQty === '' || safeMaxQty === undefined || safeMaxQty === null) {
          callback();
        } else if (Number(safeMaxQty) <= Number(safeMinQty)) {
          callback(new Error('最低库存需小于最高库存'));
        }
      }
    };
    return {
      formData: {
        tableDataAvailable: []
      },
      listIndex: '',
      //表单校验
      rules: {
        'tableDataAvailable.safeMinQty': [
          { validator: safeMinQtyFun, trigger: ['change', 'blur'] }
        ],
        'tableDataAvailable.safeMaxQty': [
          { validator: safeMaxQtyFun, trigger: ['change', 'blur'] }
        ],
      },
      //本地
      locals: [],
      //缓存数组  储存已经在数据库然后点减号的数据
      cacheList: [],
      radioItem: undefined, // 单选 storeId: undefined, //焦点方案id
      selectPriceCaseName: '商品信息', // 当前选中的方案信息的名称
      loading: false, // 遮罩层
      loadingDetail: false, // 价格方案表格的加载
      loadingAvailable: false, // 可用商品表格的加载
      tableDataAvailable: [], // 可用商品表格数据
      queryCode: undefined, // 传送商品参数
      OpenGoods: false, // 选择商品信息开关
      loading: false,
      loadingTable: false,
      multiple: false, // 非单个禁用
      ids: [], // 选中数组
      total: 0, // 总条数
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      tableCellLabel: '序号'
    }
  },

  async created () {
    await this.getList()
  },
  methods: {
    focusIndex (index) {
      this.listIndex = index
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await totalSafeStockListAPI(this.queryParams)
      rows.forEach(item => {
        this.$set(item, 'totalSafeStockId', 666)
        this.$set(item, 'hoverRow', false)
      })
      this.formData.tableDataAvailable = rows
      this.total = total
      if (this.formData.tableDataAvailable.length <= 0) {
        this.formData.tableDataAvailable = [{}]
      }
      //刷新缓存
      this.cacheList = []
      this.loadingTable = false
    },
    /** 保存按钮 */
    async submitForm () {
      // if (this.formData.tableDataAvailable.length <= 0) {
      //   this.$message.error('商品不能为空')
      //   return
      // }
      const length = this.formData.tableDataAvailable.filter(item =>(item.safeMinQty < item.safeMaxQty))
      const safeMinQtyLength = this.formData.tableDataAvailable.filter(item =>(item.safeMinQty == '' || item.safeMinQty == undefined || item.safeMinQty == null))
      const safeMaxQtyLength = this.formData.tableDataAvailable.filter(item =>(item.safeMaxQty == '' || item.safeMaxQty == undefined || item.safeMaxQty == null))
      let tableLength = this.formData.tableDataAvailable.length
      if (length.length != tableLength || safeMinQtyLength.length != 0 || safeMaxQtyLength.length != 0) {
        this.$message.error('请输入正确的商品信息')
        return
      }
      //判断缓存区有没有删除的东西
      if (this.cacheList.length > 0) {
        //删除接口
        await totalSafeStockRemoveAPI(this.cacheList)
      }
      await totalSaveOrUpdateAllAPI(this.formData.tableDataAvailable)
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      this.listIndex = ''
      await this.getList()
      if (this.formData.tableDataAvailable.length <= 0) {
        this.formData.tableDataAvailable = [{}]
      }
    },
    // 选中的商品
    getGoodS (value) {
      this.loadingAvailable = true
      if (value.length == 0) {
        this.loadingAvailable = false
        return
      }
      // 去重
      value = value.filter(item => {
        return this.formData.tableDataAvailable.every(v => v.goodsId != item.goodsId)
      })
      if (value.length == 0) {
        this.loadingAvailable = false
        return
      }
      this.formData.tableDataAvailable.push(...value)
      this.formData.tableDataAvailable = this.formData.tableDataAvailable.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )
      this.loadingAvailable = false
    },
    // 适用商品表格当中增加/减少一行方法
    rowAvailable (name, index, goodsId, totalSafeStockId) {
      if (name === 'push') {
        this.formData.tableDataAvailable.splice(index + 1, 0, {})
      } else {
        if (totalSafeStockId) {
          this.cacheList.push(goodsId)
        }
        this.formData.tableDataAvailable.splice(index, 1)
      }
    },

    // 删除
    async getDelete (ids) {
      this.$confirm('此操作需先保存才永久删除该商品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if (this.ids.length > 0) {
            // 删除api
            await totalSafeStockRemoveAPI(ids),
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.ids = []
              this.getList()
          }
          if (this.locals.length > 0) {
            for (let i = this.locals.length - 1; i >= 0; i--) {
              this.formData.tableDataAvailable.splice(this.locals[i], 1)
            }
          }
        })
        .catch(() => {})
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      // 筛选单据id 数据库
      this.ids = selection
        .map(item => {
          if (item.totalSafeStockId) {
            return item.goodsId
          }
        })
        .filter(item => typeof item !== 'undefined') // 单据id

      // 筛选单据id  本地数
      this.locals = selection.map(item => this.formData.tableDataAvailable.indexOf(item))
      // 单据id
      this.multiple = !selection.length
    },

    // 点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 刷新按钮
    test (e) {
      let target = e.target
      if (target.nodeName == 'I' || target.nodeName == 'SPAN') {
        target = e.target.parentNode
      }
      target.blur()
      this.getList()
    },
    // 点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await totalSafeStockListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        // 重新渲染表格
        this.tableData = res.rows
        // 表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        // 取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter (row) {
      let eleIndex = this.formData.tableDataAvailable.indexOf(row)
      this.formData.tableDataAvailable[eleIndex].hoverRow = true
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave () {
      for (let index = 0; index < this.formData.tableDataAvailable.length; index++) {
        const element = this.formData.tableDataAvailable[index]
        element.hoverRow = false
      }
      this.tableCellLabel = '序号'
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 6px 10px;
  background-color: #eaeaea;
  //仓库

  // 搜索
  .navSearch {
    padding: 10px;
  }
}

//输入框原生属性 /** * 解决el-input设置类型为number时，中文输入法光标上移问题
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/** *
解决el-input设置类型为number时，去掉输入框后面上下箭头 **/
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
