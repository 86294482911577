<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import {
  listAssembleGoodsDetail,
  sumListAssembleGoodsDetail,
  listSummaryAssembleGoods,
  sumListSummaryAssembleGoods,
  listDisassembleGoodsDetail,
  sumListDisassembleGoodsDetail,
  listSummaryDisassembleGoods,
  sumListSummaryDisassembleGoods,
} from "@/api/combinStrip/index";
export default {
  name: "PassengerFlow",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        summary: "",
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        body: {},
        title: "组装拆卸查询",
        rowKey: "shopId",
        search: [
          {
            label: "日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/商品编码/商品名称/商品条码/手工单据号/商品批号/备注",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "relateGoodsNos", label: "商品编码" },
              { filter: "relateGoodsNames", label: "商品名称" },
              { filter: "relateBarcodes", label: "商品条码" },
              { filter: "handBillNos", label: "手工单据号" },
              { filter: "relateGoodsBatchNos", label: "商品批号" },
              { filter: "billRemarks", label: "备注" },
            ],
            hide: () => ["assemblyDetail", "disassemblyDetail"].includes(this.options.tabColumnType),
          },
          {
            type: "filters",
            tip: "全部/商品编码/商品名称/商品条码",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "relateGoodsNos", label: "商品编码" },
              { filter: "relateGoodsNames", label: "商品名称" },
              { filter: "relateBarcodes", label: "商品条码" },
            ],
            hide: () => ["assemblySummary", "disassemblySummary"].includes(this.options.tabColumnType),
          },
          this.$select({
            key: "listGoods",
            option: {
              filter: "relateGoodsIds",
              seniorSearch: true,
              hide: () => ["assemblyDetail", "assemblySummary",].includes(this.options.tabColumnType),
              label:"组装商品",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          
          this.$select({
            key: "listGoods",
            option: {
              filter: "detailGoodsIds",
              seniorSearch: true,
              hide: () => ["assemblyDetail", "assemblySummary"].includes(this.options.tabColumnType),
              label: "领出商品",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              filter: "inStoreIds",
              seniorSearch: true,
              hide: () => ["assemblyDetail", "assemblySummary"].includes(this.options.tabColumnType),
              label: "入库仓库",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择入库仓库",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "store" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              filter: "detailOutStoreIds",
              seniorSearch: true,
              label: "领出仓库",
              hide: () => ["assemblyDetail", "assemblySummary"].includes(this.options.tabColumnType),
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择领出仓库",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "store" }),
                    },
                  },
                ],
              },
            },
          }),
          // == 拆卸
          this.$select({
            key: "listGoods",
            option: {
              filter: "relateGoodsIds",
              seniorSearch: true,
              hide: () => ["disassemblyDetail", "disassemblySummary",].includes(this.options.tabColumnType),
              label:"拆卸商品",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listGoods",
            option: {
              filter: "detailGoodsIds",
              seniorSearch: true,
              hide: () => ["disassemblyDetail", "disassemblySummary"].includes(this.options.tabColumnType),
              label: "入库商品",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "goods" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              filter: "detailInStoreIds",
              seniorSearch: true,
              hide: () => ["disassemblyDetail", "disassemblySummary"].includes(this.options.tabColumnType),
              label: "入库仓库",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择入库仓库",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "store" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listStore",
            option: {
              filter: "outStoreIds",
              seniorSearch: true,
              label: "领出仓库",
              hide: () => ["disassemblyDetail", "disassemblySummary"].includes(this.options.tabColumnType),
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择领出仓库",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "store" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "includeBillStatus",
            option: { 
              seniorSearch: true,
              hide: () => ["assemblyDetail", "assemblySummary"].includes(this.options.tabColumnType), 
              option: { multiple: true }
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "receipt",
        tabsColumns: [
          {
            title: "组装明细查询",
            type: "assemblyDetail",
            getSummaryApi: async (e) => {
              const res = await sumListAssembleGoodsDetail(e);
              const [{ unitSaleMoney, unitPurMoney, unitQty } = {}] =
                res?.data?.details || [];
              return {
                ...res?.data,
                unitSaleMoney,
                unitPurMoney,
                unitQty,
              };
            },
            getListApi: listAssembleGoodsDetail,
            getDataKey: (res) => {
              let list = [];
              res.rows.forEach(({ details: arr, ...item }) => {
                list = [
                  ...list,
                  ...(arr?.length
                    ? arr.map((v, i) => ({
                        ...item,
                        details: v,
                        spanNum: arr.length,
                        spanStart: i === 0,
                      }))
                    : [{ ...item, details: {} }]),
                ];
              });
              return { list, total: res.total };
            },
            handleEventRowClass: ({ row, column, rowIndex, columnIndex }) => {
              if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24].includes(columnIndex)) {
                if (row.spanStart /**&& row.spanNum > 1 */) {
                  return [row.spanNum, 1];
                } else {
                  return [0, 0];
                }
              }
            },
            exportOption: {
              fastExportUrl:
                "/api/system/store/assemble/goods/exportListAssembleGoodsDetail",
              exportName: "组装明细查询",
            },
            columns: [
              {
                prop: "billNo",
                label: "单据编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "goodsNo",
                label: "组装商品编号",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsNo ? row.relateGoodsInfo?.goodsNo : "",
              },
              {
                prop: "goodsName",
                label: "组装商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsName ? row.relateGoodsInfo?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "组装商品条码",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.barcode ? row.relateUnitInfo?.barcode : "",
              },
              {
                prop: "goodsSpec",
                label: "组装规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.goodsSpec ? row.relateUnitInfo?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "组装单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.unitName ? row.relateUnitInfo?.unitName : "",
              },
              {
                prop: "storeName",
                label: "入库仓库",
                minWidth: 150,
                formatter: (v, row) =>
                  row.inStoreInfo?.storeName ? row.inStoreInfo?.storeName : "",
              },
              {
                prop: "relateGoodsQty",
                label: "组装数量",
                minWidth: 150,
              },
              {
                prop: "purPrice",
                label: "组装进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.purPrice ? row.relateUnitInfo?.purPrice : "",
              },
              {
                prop: "relateUnitPurMoney",
                label: "组装进价金额",
                minWidth: 150,
              },
              {
                prop: "salePrice",
                label: "组装售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.salePrice ? row.relateUnitInfo?.salePrice : "",
              },
              {
                prop: "relateUnitSaleMoney",
                label: "组装售价金额",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "领出商品编码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.goodsNo ? row.details?.goodsNo : ""),
              },
              {
                prop: "goodsName",
                label: "领出商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsName ? row.details?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "领出商品条码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.barcode ? row.details?.barcode : ""),
              },
              {
                prop: "goodsSpec",
                label: "领出规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsSpec ? row.details?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "领出单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitName ? row.details?.unitName : "",
              },
              {
                prop: "outStoreName",
                label: "领出仓库",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.outStoreName ? row.details?.outStoreName : "",
              },
              {
                prop: "unitQty",
                label: "领出数量",
                minWidth: 150,
                formatter: (v, row) => (row.details?.unitQty ? row.details?.unitQty : ""),
              },
              {
                prop: "unitPurPrice",
                label: "领出进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurPrice ? row.details?.unitPurPrice : "",
              },
              {
                prop: "unitPurMoney",
                label: "领出进价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurMoney ? row.details?.unitPurMoney : "",
              },
              {
                prop: "unitSalePrice",
                label: "领出售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSalePrice ? row.details?.unitSalePrice : "",
              },
              {
                prop: "unitSaleMoney",
                label: "领出售价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSaleMoney ? row.details?.unitSaleMoney : "",
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
                formatter: (v, row) => (row.details?.remark ? row.details?.remark : ""),
              },
              {
                prop: "billRemark",
                label: "单据备注",
                minWidth: 150,
              },
            ],
            summary: [
              "unitSaleMoney",
              "unitPurMoney",
              "unitQty",
              "relateUnitSaleMoney",
              "relateUnitPurMoney",
              "relateGoodsQty",
            ],
          },
          {
            title: "组装汇总查询",
            type: "assemblySummary",
            getSummaryApi: async (e) => {
              const res = await sumListSummaryAssembleGoods(e);
              const [{ unitSaleMoney, unitPurMoney, unitQty } = {}] =
                res?.data?.details || [];
              return {
                ...res?.data,
                unitSaleMoney,
                unitPurMoney,
                unitQty,
              };
            },
            getListApi: listSummaryAssembleGoods,
            getDataKey: (res) => {
              let list = [];
              res.rows.forEach(({ details: arr, ...item }) => {
                list = [
                  ...list,
                  ...(arr?.length
                    ? arr.map((v, i) => ({
                        ...item,
                        details: v,
                        spanNum: arr.length,
                        spanStart: i === 0,
                      }))
                    : [{ ...item, details: {} }]),
                ];
              });
              return { list, total: res.total };
            },
            handleEventRowClass: ({ row, column, rowIndex, columnIndex }) => {
              if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(columnIndex)) {
                if (row.spanStart) {
                  return [row.spanNum, 1];
                } else {
                  return [0, 0];
                }
              }
            },
            exportOption: {
              fastExportUrl:
                "/api/system/store/assemble/goods/exportListSummaryAssembleGoods",
              exportName: "组装汇总查询",
            },
            columns: [
              {
                prop: "goodsNo",
                label: "组装商品编号",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsNo ? row.relateGoodsInfo?.goodsNo : "",
              },
              {
                prop: "goodsName",
                label: "组装商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsName ? row.relateGoodsInfo?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "组装商品条码",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.barcode ? row.relateUnitInfo?.barcode : "",
              },
              {
                prop: "goodsSpec",
                label: "组装规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.goodsSpec ? row.relateUnitInfo?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "组装单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.unitName ? row.relateUnitInfo?.unitName : "",
              },
              {
                prop: "relateGoodsQty",
                label: "组装数量",
                minWidth: 150,
              },
              {
                prop: "purPrice",
                label: "组装进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.purPrice ? row.relateUnitInfo?.purPrice : "",
              },
              {
                prop: "relateUnitPurMoney",
                label: "组装进价金额",
                minWidth: 150,
              },
              {
                prop: "salePrice",
                label: "组装售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.salePrice ? row.relateUnitInfo?.salePrice : "",
              },
              {
                prop: "relateUnitSaleMoney",
                label: "组装售价金额",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "领出商品编码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.goodsNo ? row.details?.goodsNo : ""),
              },
              {
                prop: "goodsName",
                label: "领出商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsName ? row.details?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "领出商品条码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.barcode ? row.details?.barcode : ""),
              },
              {
                prop: "goodsSpec",
                label: "领出规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsSpec ? row.details?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "领出单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitName ? row.details?.unitName : "",
              },
              {
                prop: "unitQty",
                label: "领出数量",
                minWidth: 150,
                formatter: (v, row) => (row.details?.unitQty ? row.details?.unitQty : ""),
              },
              {
                prop: "unitPurPrice",
                label: "领出进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurPrice ? row.details?.unitPurPrice : "",
              },
              {
                prop: "unitPurMoney",
                label: "领出进价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurMoney ? row.details?.unitPurMoney : "",
              },
              {
                prop: "unitSalePrice",
                label: "领出售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSalePrice ? row.details?.unitSalePrice : "",
              },
              {
                prop: "unitSaleMoney",
                label: "领出售价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSaleMoney ? row.details?.unitSaleMoney : "",
              },
            ],
            summary: [
              "unitSaleMoney",
              "unitPurMoney",
              "unitQty",
              "relateUnitSaleMoney",
              "relateUnitPurMoney",
              "relateGoodsQty",
            ],
          },
          {
            title: "拆卸明细查询",
            type: "disassemblyDetail",
            getSummaryApi: async (e) => {
              const res = await sumListDisassembleGoodsDetail(e);
              const [{ unitSaleMoney, unitPurMoney, unitQty } = {}] =
                res?.data?.details || [];
              return {
                ...res?.data,
                unitSaleMoney,
                unitPurMoney,
                unitQty,
              };
            },
            getListApi: listDisassembleGoodsDetail,
            getDataKey: (res) => {
              let list = [];
              res.rows.forEach(({ details: arr, ...item }) => {
                list = [
                  ...list,
                  ...(arr?.length
                    ? arr.map((v, i) => ({
                        ...item,
                        details: v,
                        spanNum: arr.length,
                        spanStart: i === 0,
                      }))
                    : [{ ...item, details: {} }]),
                ];
              });
              return { list, total: res.total };
            },
            handleEventRowClass: ({ row, column, rowIndex, columnIndex }) => {
              if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24].includes(columnIndex)) {
                if (row.spanStart) {
                  return [row.spanNum, 1];
                } else {
                  return [0, 0];
                }
              }
            },
            exportOption: {
              fastExportUrl:
                "/api/system/store/disassemble/goods/exportListDisassembleGoodsDetail",
              exportName: "拆卸明细查询",
            },
            columns: [
              {
                prop: "billNo",
                label: "单据编号",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billDate",
                label: "单据日期",
                minWidth: 155,
              },
              {
                prop: "goodsNo",
                label: "拆卸商品编号",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsNo ? row.relateGoodsInfo?.goodsNo : "",
              },
              {
                prop: "goodsName",
                label: "拆卸商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsName ? row.relateGoodsInfo?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "拆卸商品条码",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.barcode ? row.relateUnitInfo?.barcode : "",
              },
              {
                prop: "goodsSpec",
                label: "拆卸规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.goodsSpec ? row.relateUnitInfo?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "拆卸单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.unitName ? row.relateUnitInfo?.unitName : "",
              },
              {
                prop: "storeName",
                label: "领出仓库",
                minWidth: 150,
                formatter: (v, row) =>
                  row.outStoreInfo?.storeName ? row.outStoreInfo?.storeName : "",
              },
              {
                prop: "relateGoodsQty",
                label: "拆卸数量",
                minWidth: 150,
              },
              {
                prop: "purPrice",
                label: "拆卸进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.purPrice ? row.relateUnitInfo?.purPrice : "",
              },
              {
                prop: "relateUnitPurMoney",
                label: "拆卸进价金额",
                minWidth: 150,
              },
              {
                prop: "salePrice",
                label: "拆卸售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.salePrice ? row.relateUnitInfo?.salePrice : "",
              },
              {
                prop: "relateUnitSaleMoney",
                label: "拆卸售价金额",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "入库商品编码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.goodsNo ? row.details?.goodsNo : ""),
              },
              {
                prop: "goodsName",
                label: "入库商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsName ? row.details?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "入库商品条码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.barcode ? row.details?.barcode : ""),
              },
              {
                prop: "goodsSpec",
                label: "入库规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsSpec ? row.details?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "入库单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitName ? row.details?.unitName : "",
              },
              {
                prop: "inStoreName",
                label: "入库仓库",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.inStoreName ? row.details?.inStoreName : "",
              },
              {
                prop: "unitQty",
                label: "入库数量",
                minWidth: 150,
                formatter: (v, row) => (row.details?.unitQty ? row.details?.unitQty : ""),
              },
              {
                prop: "unitPurPrice",
                label: "入库进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurPrice ? row.details?.unitPurPrice : "",
              },
              {
                prop: "unitPurMoney",
                label: "入库进价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurMoney ? row.details?.unitPurMoney : "",
              },
              {
                prop: "unitSalePrice",
                label: "入库售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSalePrice ? row.details?.unitSalePrice : "",
              },
              {
                prop: "unitSaleMoney",
                label: "入库售价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSaleMoney ? row.details?.unitSaleMoney : "",
              },
              {
                prop: "remark",
                label: "备注",
                minWidth: 150,
                formatter: (v, row) => (row.details?.remark ? row.details?.remark : ""),
              },
              {
                prop: "billRemark",
                label: "单据备注",
                minWidth: 150,
              },
            ],
            summary: [
              "unitSaleMoney",
              "unitPurMoney",
              "unitQty",
              "relateUnitSaleMoney",
              "relateUnitPurMoney",
              "relateGoodsQty",
            ],
          },
          {
            title: "拆卸汇总查询",
            type: "disassemblySummary",
            getSummaryApi: async (e) => {
              const res = await sumListSummaryDisassembleGoods(e);
              const [{ unitSaleMoney, unitPurMoney, unitQty } = {}] =
                res?.data?.details || [];
              return {
                ...res?.data,
                unitSaleMoney,
                unitPurMoney,
                unitQty,
              };
            },
            getListApi: listSummaryDisassembleGoods,
            getDataKey: (res) => {
              let list = [];
              res.rows.forEach(({ details: arr, ...item }) => {
                list = [
                  ...list,
                  ...(arr?.length
                    ? arr.map((v, i) => ({
                        ...item,
                        details: v,
                        spanNum: arr.length,
                        spanStart: i === 0,
                      }))
                    : [{ ...item, details: {} }]),
                ];
              });
              return { list, total: res.total };
            },
            handleEventRowClass: ({ row, column, rowIndex, columnIndex }) => {
              if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10].includes(columnIndex)) {
                if (row.spanStart) {
                  return [row.spanNum, 1];
                } else {
                  return [0, 0];
                }
              }
            },
            exportOption: {
              fastExportUrl:
                "/api/system/store/disassemble/goods/exportListSummaryDisassembleGoods",
              exportName: "拆卸汇总查询",
            },
            columns: [
              {
                prop: "goodsNo",
                label: "拆卸商品编号",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsNo ? row.relateGoodsInfo?.goodsNo : "",
              },
              {
                prop: "goodsName",
                label: "拆卸商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateGoodsInfo?.goodsName ? row.relateGoodsInfo?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "拆卸商品条码",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.barcode ? row.relateUnitInfo?.barcode : "",
              },
              {
                prop: "goodsSpec",
                label: "拆卸规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.goodsSpec ? row.relateUnitInfo?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "拆卸单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.unitName ? row.relateUnitInfo?.unitName : "",
              },
              {
                prop: "relateGoodsQty",
                label: "拆卸数量",
                minWidth: 150,
              },
              {
                prop: "purPrice",
                label: "拆卸进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.purPrice ? row.relateUnitInfo?.purPrice : "",
              },
              {
                prop: "relateUnitPurMoney",
                label: "拆卸进价金额",
                minWidth: 150,
              },
              {
                prop: "salePrice",
                label: "拆卸售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.relateUnitInfo?.salePrice ? row.relateUnitInfo?.salePrice : "",
              },
              {
                prop: "relateUnitSaleMoney",
                label: "拆卸售价金额",
                minWidth: 150,
              },
              {
                prop: "goodsNo",
                label: "入库商品编码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.goodsNo ? row.details?.goodsNo : ""),
              },
              {
                prop: "goodsName",
                label: "入库商品名称",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsName ? row.details?.goodsName : "",
              },
              {
                prop: "barcode",
                label: "入库商品条码",
                minWidth: 150,
                formatter: (v, row) => (row.details?.barcode ? row.details?.barcode : ""),
              },
              {
                prop: "goodsSpec",
                label: "入库规格",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.goodsSpec ? row.details?.goodsSpec : "",
              },
              {
                prop: "unitName",
                label: "入库单位",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitName ? row.details?.unitName : "",
              },
              {
                prop: "unitQty",
                label: "入库数量",
                minWidth: 150,
                formatter: (v, row) => (row.details?.unitQty ? row.details?.unitQty : ""),
              },
              {
                prop: "unitPurPrice",
                label: "入库进价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurPrice ? row.details?.unitPurPrice : "",
              },
              {
                prop: "unitPurMoney",
                label: "入库进价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitPurMoney ? row.details?.unitPurMoney : "",
              },
              {
                prop: "unitSalePrice",
                label: "入库售价",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSalePrice ? row.details?.unitSalePrice : "",
              },
              {
                prop: "unitSaleMoney",
                label: "入库售价金额",
                minWidth: 150,
                formatter: (v, row) =>
                  row.details?.unitSaleMoney ? row.details?.unitSaleMoney : "",
              },
            ],
            summary: [
              "unitSaleMoney",
              "unitPurMoney",
              "unitQty",
              "relateUnitSaleMoney",
              "relateUnitPurMoney",
              "relateGoodsQty",
            ],
          },
        ],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {

    },
  },
};
</script>
