<template>
  <!-- 商品库存明细 -->
  <div class="stock-query">
    <el-card :body-style="{ padding: '10px 10px 0 10px' }">
      <el-form>
        <!-- 搜索区域 -->
        <searchTitle @clickSearch="getList">
          <template #selectTop>
            <el-form-item label="商品类别">
              <el-select
                class="billType marR5"
                v-model="params.categoryIds"
                placeholder="请选择"
                filterable
                clearable
                multiple
                collapse-tags
                size="mini"
              >
                <el-option
                  v-for="item in categoryData"
                  :key="item.categoryId"
                  :label="item.categoryName"
                  :value="item.categoryId"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="商品名称">
              <SelectRemote
                v-model="params.goodsIds"
                :option="goodsOption"
                :filterable="true"
                :multiple="true"
                :clearable="true"
                placeholder="请选择"
              />
            </el-form-item>
            <el-form-item label="仓库">
              <el-select
                class="billType marR5"
                v-model="params.storeIds"
                placeholder="请选择"
                filterable
                clearable
                multiple
                collapse-tags
                size="mini"
              >
                <el-option
                  v-for="item in storeData"
                  :key="item.storeId"
                  :label="item.storeName"
                  :value="item.storeId"
                />
              </el-select>
            </el-form-item>
          </template>
        </searchTitle>
      </el-form>
      <!-- 表格区域 -->
      <allTable
        :tableData="tableData"
        :columnLabel="columnLabel"
        :columnWidth="columnWidth"
        :firstLabel="firstLabel"
        :NoLabel="NoLabel"
        :secondLevelOneLabel="secondLevelOneLabel"
        :secondLevelTwoLabel="secondLevelTwoLabel"
        @getRowData="getGoodsData"
      >
      </allTable>

      <bottomPage
        v-model="queryParams"
        :getList="getList"
        :totalCom="total"
        :isSelectAll="false"
      />
      <stockQueryDetail
        :openStockQuery.sync="openStockQuery"
        :goodsDetailData="goodsDetailData"
      ></stockQueryDetail>
    </el-card>
  </div>
</template>

<script>
import bottomPage from '@/views/components/bottomPage' //底部分页
import searchTitle from '@/views/components/searchTitle/index.vue' //搜索组件
import allTable from '../components/all-table.vue' //表格组件
import Treeselect from '@riophae/vue-treeselect' //搜索tree下拉框
import '@riophae/vue-treeselect/dist/vue-treeselect.css' //搜索tree下拉框样式
import stockQueryDetail from './components/stockQueryDetail.vue' //商品库存详情明细对话框组件
import {
  storeInventoryGoodsList,
  storeInventoryGoodsDetail
} from '@/api/storeReport' //接口api
import { getData } from '@/utils/public' //获取下拉框数据
import { listCategory } from '@/api/goods/category'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { listGoods } from '@/api/goods/goods' //商品列表
// import { allStoreList } from '@/api/system/store' //仓库

export default {
  name: 'StockQuery',
  components: {
    searchTitle,
    Treeselect,
    allTable,
    stockQueryDetail,
    bottomPage,
    SelectRemote
  },
  data () {
    return {
      //遮罩层
      loading: false,
      //搜索对象
      params: {
        pageSize: 15, //页容量
        pageNum: 1, //页码
        storeIds: [], //仓库
        categoryIds: [], //商品类别
        goodsIds: [] //商品名称
      },
      //下拉框数据
      options: [],
      //商品类别数据
      categoryData: [],
      //商品数据
      goodsData: [],
      //仓库数据
      storeData: [],
      //单据日期
      billDate: undefined,
      //记账日期
      accountingDate: undefined,
      //表格数据
      tableData: [],
      //表格数据总数
      total: 0,
      //商品库存详情数据
      goodsDetailData: {},
      //商品详情弹出框开关
      openStockQuery: false,
      //表格列宽
      columnWidth: '160',
      //表格第一列字段
      firstLabel: [{ label: '仓库', prop: 'storeName' }],
      //表格编号字段
      NoLabel: ['商品编码', 'goodsNo'],
      //表格列字段
      columnLabel: [
        { label: '商品名称', prop: 'goodsName' },
        { label: '商品条码', prop: 'barcode' },
        { label: '商品批号', prop: 'batchNo' },
        { label: '商品规格', prop: 'goodsSpec' },
        { label: '基础单位', prop: 'unitName' },
        { label: '期末数', prop: 'finalQty' },
        { label: '期末金额', prop: 'finalMoney' },
        { label: '期初数', prop: 'firstQty' },
        { label: '期初金额', prop: 'firstMoney' },
        { label: '入库数', prop: 'inQty' },
        { label: '入库金额', prop: 'inMoney' },
        { label: '出库数', prop: 'outQty' },
        { label: '出库金额', prop: 'outMoney' }
      ],
      //表格二级表格字段第一个
      secondLevelOneLabel: [
        {
          label: '入库',
          prop: 'billDate',
          children: [
            { label: '生产数', prop: 'billD' },
            { label: '生产金额', prop: 'billDa' },
            { label: '采购数', prop: 'purQty' },
            { label: '采购金额', prop: 'purMoney' },
            { label: '调入数', prop: 'moveInQty' },
            { label: '调入金额', prop: 'moveInMoney' },
            { label: '库存调整入库数', prop: 'storeBalanceInQty' },
            { label: '库存调整入库金额', prop: 'storeBalanceInMoney' },
            { label: '门店销售退货数', prop: 'billDat' },
            { label: '门店销售退货金额', prop: 'billDate' },
            { label: '销售退货数', prop: 'saleBackQty' },
            { label: '销售退货金额', prop: 'saleBackMoney' },
            { label: '盘盈数', prop: 'adjustQty' },
            { label: '盘盈金额', prop: 'adjustMoney' },
            { label: '其他入库数', prop: 'otherInQty' },
            { label: '其他入库金额', prop: 'otherInMoney' }
          ]
        }
      ],
      //表格二级表格字段第二个
      secondLevelTwoLabel: [
        {
          label: '出库',
          prop: 'billDate',
          children: [
            { label: '生产领料数', prop: 'b' },
            { label: '生产领料金额', prop: 'bi' },
            { label: '销售数', prop: 'saleQty' },
            { label: '销售金额', prop: 'saleMoney' },
            { label: '调出数', prop: 'moveOutQty' },
            { label: '调出金额', prop: 'moveOutMoney' },
            { label: '库存调整出库数', prop: 'storeBalanOutQty' },
            { label: '库存调整出库金额', prop: 'storeBalanceOutMoney' },
            { label: '门店销售数', prop: 'bil' },
            { label: '门店销售金额', prop: 'bill' },
            { label: '采购退货数', prop: 'purBackQty' },
            { label: '采购退货金额', prop: 'purBackMoney' },
            { label: '盘亏数', prop: 'loseQty' },
            { label: '盘亏金额', prop: 'loseMoney' },
            { label: '其他出库数', prop: 'otherOutQty' },
            { label: '其他出库金额', prop: 'moveOutQty' }
          ]
        }
      ],
      goodsOption: {
        remote: listGoods,
        value: 'goodsId',
        label: 'goodsName',
        filterable: true,
        downLoad: 10
      }
    }
  },
  async created () {
    this.getList() //调用列表数据
    //下拉框数据
    const res1 = await listCategory()
    this.categoryData = res1.rows //商品类别数据
    this.storeData = await getData('store') //仓库数据
    // this.goodsData = await getData('goodsName') //商品数据
  },
  methods: {
    //商品类别下拉框treeSelect结构属性转换
    normalizerCategory (node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },
    //仓库下拉框treeSelect结构属性转换
    normalizerStore (node) {
      return {
        id: node.storeId,
        label: node.storeName
      }
    },
    //商品下拉框treeSelect结构属性转换
    normalizerGoods (node) {
      return {
        id: node.goodsId,
        label: node.goodsName
      }
    },
    //获取列表
    getList () {
      this.loading = true
      storeInventoryGoodsList(
        this.params,
        '/api/system/store/report/book/listStoreBook'
      ).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    //获取点击商品信息
    getGoodsData (data) {
      let params = {}
      params.goodsId = data.goodsId
      //当批号存在时 赋值
      if (data.batchNo) params.batchNo = data.batchNo
      params.storeId = data.storeId
      storeInventoryGoodsDetail(
        '/api/system/store/report/book/getStoreBookDetail',
        params
      ).then(response => {
        this.goodsDetailData = response.data //商品详情赋值
        this.openStockQuery = true //打开商品详情弹出框
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.stock-query {
  // background-color: #f3f3f3;
  padding: 10px;
  //下拉框选中的tag高度
  .vue-treeselect {
    ::v-deep.vue-treeselect__input-container {
      display: none !important;
    }
    ::v-deep.vue-treeselect__multi-value {
      line-height: 20px !important;
    }
    ::v-deep.vue-treeselect__multi-value-item {
      line-height: 20px !important;
    }
  }
  .el-form {
    //文本框/下拉框宽度
    .el-input,
    .el-select,
    .vue-treeselect {
      width: 300px;
    }
  }
}
</style>
