<template>
  <!-- 仓库调入单 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import {
  franchiseHttpBillInList
} from "@/api/joinClient/bill/moveIn";//加盟配送差异
import TablePage from '@/components/tablePage'
import { updateInstoreStatus } from '@/api/purchase/index'
import { httpBillInList } from '@/api/store/index'

const { cloneDeep } = require('lodash')

export default {
  name: 'moveInCom',
  components: { TablePage },
  props: {
    shopType: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        getListApi: this.shopType == 2 ? franchiseHttpBillInList : httpBillInList,
        body: {},
        defaultBody: {},
        listNo: true, // 序号
        title: '单据信息',
        check: [], // 选中数据
        rowKey: 'billId',
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'month',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            label: '单据编号',
            seniorSearch: true,
            type: 'input',
            model: '',
            filter: 'billNo'
          },
          this.$select({
            key: "listSendStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listDeliveryStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "dataBillStatus",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary',
            disabled: false
          },
          {
            click: 'audit',
            label: '审核',
            type: 'success',
            btnType: 'dropdown',
            disabled: false,
            other: [
              {
                click: 'redoAudit',
                label: '反审核',
                disabled: false
              }
            ]
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: false
          },
          {
            click: 'stop',
            label: '终止',
            icon: 'el-icon-remove-outline',
            type: 'primary',
            disabled: false
          }
        ],
        columns: [
          {
            prop: 'billNo',
            label: '单据编号',
            type: 'link',
            click: 'routerLink',
            minWidth: 180
          },
          {
            prop: 'billDate',
            label: '单据日期',
            minWidth: 120
          },
          {
            prop: 'billStatusName',
            label: '单据状态',
            minWidth: 120,
          },
          {
            prop: 'outStoreName',
            label: '发货仓库',
            minWidth: 140
          },
          {
            prop: 'inStoreName',
            label: '收货仓库',
            minWidth: 160
          },
          {
            prop: 'billMoney',
            label: '单据金额',
            minWidth: 120
          },
          {
            prop: 'sourceBillNo',
            label: '源单据号',
            minWidth: 120
          },
          {
            prop: 'sourceBillTypeName',
            label: '源单据类型',
            minWidth: 120
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 160
          },
          {
            prop: 'auditBy',
            label: '审核人',
            minWidth: 120
          },
          {
            prop: 'auditTime',
            label: '审核时间',
            minWidth: 120
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 120
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 160
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 160
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check
      let url = '/api/system/store/bill/in/updateBillStatus'
      let billType = '130106'
      let pathName = 'storeMoveInDetail'
      if (this.shopType == 2) {
        url = "/api/system/franchise/store/bill/in/updateBillStatus";
        pathName = 'JoinClientStoreMoveInDetail'
      }
      switch (type) {
        case 'routerLink':
          {
            const billId = row.billId
            this.$router.push({
              name: pathName,
              query: {
                billId: billId,
                type: 'Update',
                shopType: this.shopType,
              }
            })
          }
          break
        case 'add':
          {
            this.$router.push({
              name: pathName,
              query: {
                type: 'Add',
                shopType: this.shopType,
              }
            })
          }
          break
        case 'audit':
          {
            if (!selectData.length) return
            this.handleBillStatus('审核', selectData, url, billType)
          }
          break
        case 'redoAudit':
          {
            if (!selectData.length) return
            this.handleBillStatus('反审核', selectData, url, billType)
          }
          break
        case 'del':
          {
            if (!selectData.length) return
            this.handleBillStatus('删除', selectData, url, billType)
          }
          break
        case 'stop':
          {
            if (!selectData.length) return
            this.handleBillStatus('关闭', selectData, url, billType)
          }
          break
        case 'getListed':
          {
            this.options.body.billStatus = undefined
          }
          break
        default:
          break
      }
    },
    seniorSearch() { },
    handleBillStatus(command, selectData, url, BillType) {
      let billData = [] //过滤的数据
      let name = '' //要执行的状态名字
      let tag = '' //提示的状态
      let billStatus = '' //要执行的状态数字
      if (command === '反审核') {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = '反审核'
        tag = '已审核'
        billStatus = '0'
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === '审核') {
          name = '审核'
          tag = '未审核'
          billStatus = '2'
        } else if (command === '删除') {
          name = '删除'
          tag = '未审核'
          billStatus = '3'
        } else if (command === '关闭') {
          name = '关闭'
          tag = '未审核'
          billStatus = '4'
        }
      }
      const billId = selectData.map(i => i.billId)
      const billNo = selectData.map(i => i.billNo)
      const $this = this
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus(
            [...billId],
            billStatus,
            url,
            BillType
          ).then(() => {
            $this.$nextTick(() => {
              $this.options.check = []
            })
            $this.$modal.msgSuccess(`${name}成功`)
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
          })
        })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == '/index') {
        vm.options.defaultBody = { includeBillStatus: [to.query.billStatus] }
        vm.options.search[5].model = [to.query.billStatus]
        let cloneData = cloneDeep(vm.options)
        vm.options = null
        vm.options = cloneData
      }
    })
  }
}
</script>
<style lang="scss" scoped></style>
