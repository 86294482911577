<template>
  <StoreMoveIn />
</template>

<script>
import StoreMoveIn from './components'
export default {
  name: 'storeMoveIn',
  components: { StoreMoveIn }
}
</script>
