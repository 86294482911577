<template>
  <!-- 商品出入库汇总表 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { httpListGoodStatisticsReport, sumListGoodStatisticsReport } from '@/api/store/index'
import { allStoreList } from '@/api/system/store' //仓库
import { updateInstoreStatus } from '@/api/purchase/index'
import { listCategory } from '@/api/goods/category' //商品类别
import { listGoods } from '@/api/goods/goods' //商品列表

export default {
  components: { TablePage },
  dicts: ['bill_status'],
  data () {
    return {
      options: {
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 10
        },
        getListApi: httpListGoodStatisticsReport,
        getSummaryApi: sumListGoodStatisticsReport,
        body: {},
        listNo: true, // 序号
        title: '单据信息',
        check: [], // 选中数据
        // rowKey: 'billId',
        exportOption: {
          show: true,
          exportApi: 'exportListGoodStatisticsReport',
          exportName: '商品出入库汇总',
          timeout: 60*5*1000
        },
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "全部/单据编号/商品编号",
            isRestore:true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" },
              { filter: "goodsNos", label: "商品编号" },
            ],
          },
          {
            label: '记账日期',
            type: 'datetimerange',
            model: '',
            seniorSearch: true,
            filterFnc: ([a, b] = []) => ({ beginBookDate: a, endBookDate: b })
          },
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listGoods",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          { label: '商品编码', prop: 'goodsNo' },
          { label: '商品名称', prop: 'goodsName' },
          { label: '商品条码', prop: 'barcode' },
          { label: '商品类别', prop: 'categoryName' },
          { label: '规格', prop: 'goodsSpec' },
          { label: '基础单位', prop: 'unitName' },
          { label: '入库数', prop: 'inQty' },
          { label: '入库成本额', prop: 'inMoney' },
          { label: '出库数', prop: 'outQty' },
          { label: '出库成本额', prop: 'outMoney' },
          { label: '品牌名称', prop: 'brandName' }
        ],
        list: [],
        summary: ['inQty', 'inMoney', 'outQty', 'outMoney']
      }
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped></style>
