var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "wrap",
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.options,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c("topOperatingButton", {
            ref: "topOperatingButton",
            attrs: {
              disabled: _vm.disableOperate,
              isExamineBtn: ![2, "2"].includes(_vm.options.billStatus),
              showAudit: _vm.isShowAudit,
              isStopBtn: false,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditForm,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c(
            "cardTitleCom",
            {
              ref: "basicInformation",
              attrs: { cardTitle: "基本信息", id: "basicInformation" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputBut",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.options.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billNo", $$v)
                            },
                            expression: "options.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "inputBut",
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disableOperate,
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.options.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billDate", $$v)
                            },
                            expression: "options.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手工单据号", prop: "handBillNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputBut",
                          attrs: {
                            disabled: _vm.disableOperate,
                            placeholder: "手工单据号",
                            size: "mini",
                            oninput: "value=value.replace(/[^0-9a-zA-Z]/g,'')",
                          },
                          model: {
                            value: _vm.options.handBillNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "handBillNo", $$v)
                            },
                            expression: "options.handBillNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "商品批号",
                          prop: "relateGoodsBatchNo",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputBut",
                          attrs: {
                            disabled: _vm.disableOperate,
                            placeholder: "商品批号",
                            size: "mini",
                            oninput: "value=value.replace(/[^0-9a-zA-Z]/g,'')",
                          },
                          model: {
                            value: _vm.options.relateGoodsBatchNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "relateGoodsBatchNo", $$v)
                            },
                            expression: "options.relateGoodsBatchNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "入库仓库", prop: "inStoreId" } },
                      [
                        _c("selectRemote", {
                          staticClass: "inputBut",
                          attrs: {
                            multiple: false,
                            option: _vm.$select({
                              key: "listStore",
                              option: {
                                option: {
                                  disabled: _vm.disableOperate,
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.options.inStoreId,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "inStoreId", $$v)
                            },
                            expression: "options.inStoreId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "组装商品", prop: "relateGoodsId" } },
                      [
                        _c("selectRemote", {
                          staticClass: "inputBut",
                          attrs: { option: _vm.goodsOptions },
                          model: {
                            value: _vm.options.relateGoodsId,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "relateGoodsId", $$v)
                            },
                            expression: "options.relateGoodsId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单位" } },
                      [
                        _c("el-input", {
                          staticClass: "inputBut",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单位",
                          },
                          model: {
                            value: _vm.options.relateUnitName,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "relateUnitName", $$v)
                            },
                            expression: "options.relateUnitName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "组装数量", prop: "relateGoodsQty" } },
                      [
                        _c("el-input", {
                          staticClass: "inputBut",
                          attrs: {
                            size: "mini",
                            oninput:
                              "value=value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g, '.').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^([0-9-, -]\\d*\\.?\\d{0,2})?.*$/,'$1').replace(/^\\./g, '').replace(/^0+(?!\\.|$)/g, ''),value = Number(value) >= 9999999 ? 9999999 : value",
                            disabled: _vm.disableOperate,
                            placeholder: "请输入优惠金额",
                          },
                          on: { blur: _vm.inputBlur },
                          model: {
                            value: _vm.options.relateGoodsQty,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "relateGoodsQty", $$v)
                            },
                            expression: "options.relateGoodsQty",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                            disabled: _vm.disableOperate,
                          },
                          model: {
                            value: _vm.options.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billRemark", $$v)
                            },
                            expression: "options.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "options", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "rightCardTitle" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "5px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          disabled: _vm.disableOperate,
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.modifyWarehouse()
                          },
                        },
                      },
                      [_vm._v("批量修改领出仓库")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("EditTable", {
                      attrs: { options: _vm.options },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }