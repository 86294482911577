<template>
  <div class="wrap">
    <!-- 关系情况 -->
    <div class="content" v-loading="loading">
      <cardTitleCom cardTitle="仓库信息" style="width: 410px;" class="cardDetail">
        <template slot="cardContent">
          <div class="tableContent">
            <el-table
              tooltip-effect="dark"
              :data="tableDataDetail"
              border
              max-height="800"
              height="800"
              v-loading="loadingDetail"
              @row-click="stroeClick"
            >
              <el-table-column width="34" align="center">
                <template slot-scope="scope">
                  <el-radio
                    v-model="radioItem"
                    :label="scope.row.storeId"
                    @click.native="handleRowClick(scope.row)"
                  >
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column
                label="序号"
                align="center"
                prop="userId"
                width="60"
                type="index"
              />
              <el-table-column
                label="仓库编号"
                align="center"
                prop="storeNo"
                width="120"
              >
              </el-table-column>
              <el-table-column label="仓库名称" width="160" prop="storeName">
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>

      <cardTitleCom :cardTitle="selectPriceCaseName" style="flex: 1;" class="cardDetail">
        <template slot="rightCardTitle">
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 10px"
            @click="submitForm"
            >保存
          </el-button>
        </template>

        <template slot="cardContent">
          <!-- <div class="cardTitle">
            <span class="title marR10">{{  }}</span>
            <el-button type="primary" size="mini" @click="submitForm"
              >保存
            </el-button>
          </div> -->

          <div class="tableContent">
            <el-form ref="form" :model="formData" :rules="rules">
              <el-table
                tooltip-effect="dark"
                :data="formData.tableDataAvailable"
                border
                max-height="800"
                height="800"
                v-loading="loadingAvailable"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
              >
                <el-table-column
                  align="center"
                  :label="tableCellLabel"
                  width="120"
                >
                  <template v-slot="scope">
                    <i
                      v-if="scope.row.hoverRow"
                      @click="rowAvailable('push', scope.$index)"
                      class="el-icon-circle-plus operatePush"
                    ></i>
                    <i
                      v-if="scope.row.hoverRow"
                      @click="
                        rowAvailable(
                          'del',
                          scope.$index,
                          scope.row.storeStandardId
                        )
                      "
                      class="el-icon-remove operateDel"
                    ></i>
                    <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>

                <el-table-column
                  label="商品编号"
                  align="center"
                  prop="goodsNo"
                  width="160"
                >
                  <template slot-scope="scope">
                    <el-input
                      style="margin-bottom: 5px"
                      v-model="scope.row.goodsNo"
                      @keyup.enter.native="keyup('goodsNo', $event, scope.$index)"
                    >
                      <i
                        slot="suffix"
                        class="el-icon-more more marT10"
                        padding-bottom="10px"
                        @click="selectShopInfo"
                      ></i>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  width="100"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="100"
                />
                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="unitName"
                  width="100"
                />
                <el-table-column
                  label="日均消耗"
                  align="center"
                  prop="dayConsumeQty"
                  width="160"
                />
                <el-table-column
                  label="最低库存"
                  align="center"
                  prop="safeMinQty"
                  width="160"
                  ><template slot-scope="scope">
                    <el-form-item
                      :prop="`tableDataAvailable[${scope.$index}].safeMinQty`" 
                      :rules="rules[`tableDataAvailable.safeMinQty`]"
                    >
                      <el-input
                        @focus="focusIndex(scope.$index)"
                        v-model="scope.row.safeMinQty"
                        type="number"
                        oninput="value=value.replace(/[^\-0-9.]/g,'')"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column
                  label="最高库存"
                  align="center"
                  prop="safeMaxQty"
                  width="160"
                  ><template slot-scope="scope">
                    <el-form-item
                      :prop="`tableDataAvailable[${scope.$index}].safeMaxQty`"
                      :rules="rules[`tableDataAvailable.safeMaxQty`]"
                    >
                      <el-input
                        @focus="focusIndex(scope.$index)"
                        v-model="scope.row.safeMaxQty"
                        type="number"
                        oninput="value=value.replace(/[^\-0-9.]/g,'')"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </template>
      </cardTitleCom>

      <!-- <el-card class="cardAvailable">

      </el-card> -->
    </div>
    <!-- 选择商品信息框 -->
    <SelectGoods
      :OpenGoods.sync="OpenGoods"
      :queryCode="queryCode"
      @getGoodS="getGoodS"
    ></SelectGoods>
  </div>
</template>

<script>
import SelectGoods from '@/views/components/selectGoodsUp' //选择商品
import { listGoods } from '@/api/goods/goods' //商品列表
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  saveOrUpdateAPI,
  safeStockDetailAPI,
  safeStockRemoveAPI
} from '@/api/store/alert/safeStock' //仓库安全库存
import { listStore } from '@/api/goods/store' //仓库
export default {
  name: 'safeStock',
  components: { cardTitleCom, SelectGoods },
  data () {
    const safeMaxQtyFun = (rule, value, callback) => {
      let safeMinQtyIndex = this.formData.tableDataAvailable[this.listIndex].safeMinQty
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入最高库存'));
      } else {
        if (safeMinQtyIndex === '' || safeMinQtyIndex === undefined || safeMinQtyIndex === null) {
          callback();
        } else if (safeMinQtyIndex >= value) {
          callback(new Error('最高库存需大于最低库存'));
        }
      }
    };

    const safeMinQtyFun = (rule, value, callback) => {
      let safeMaxQtyIndex = this.formData.tableDataAvailable[this.listIndex].safeMaxQty
      console.log(value, 'value')
      if (value === '' || value === undefined || value === null) {
        callback(new Error('请输入最低库存'));
      } else {
        if (safeMaxQtyIndex === '' || safeMaxQtyIndex === undefined || safeMaxQtyIndex === null) {
          callback();
        } else if (value >= safeMaxQtyIndex) {
          callback(new Error('最低库存需小于最高库存'));
        }
      }
    };
    return {
      formData: {
        tableDataAvailable: []
      },
      listIndex: '',
      rules: {
        'tableDataAvailable.safeMinQty': [
          { validator: safeMinQtyFun, trigger: ['change', 'blur'] }
        ],
        'tableDataAvailable.safeMaxQty': [
          { validator: safeMaxQtyFun, trigger: ['change', 'blur'] }
        ],
      },
      radioItem: undefined, //单选
      storeId: undefined, //焦点方案id
      selectPriceCaseName: '商品信息', //当前选中的方案信息的名称
      loading: false, //遮罩层
      loadingDetail: false, //价格方案表格的加载
      loadingAvailable: false, //可用门店表格的加载
      tableDataDetail: [], //价格方案表格数据
      tableDataAvailable: [], //可用门店表格数据
      queryCode: undefined, //传送门店参数
      OpenGoods: false, //选择门店信息开关
      tableCellLabel: '序号'
    }
  },
  async created () {
    this.getList().then(res => {
      if (res.length) {
        this.handleRowClick(res[0])
      }
    })
  },
  methods: {
    focusIndex (index) {
      this.listIndex = index
    },
    //方案信息信息列表
    async getList () {
      return new Promise(async resolve => {
        this.loadingDetail = true
        const res = await listStore({pageNum: 1, pageSize: 10000})
        this.tableDataDetail = res.rows
        this.loadingDetail = false
        resolve(res.rows)
      })
    },
    //保存按钮
    async submitForm () {
      const length = this.formData.tableDataAvailable.filter(item =>(item.safeMinQty < item.safeMaxQty))
      const safeMinQtyLength = this.formData.tableDataAvailable.filter(item =>(item.safeMinQty == '' || item.safeMinQty == undefined || item.safeMinQty == null))
      const safeMaxQtyLength = this.formData.tableDataAvailable.filter(item =>(item.safeMaxQty == '' || item.safeMaxQty == undefined || item.safeMaxQty == null))
      let tableLength = this.formData.tableDataAvailable.length
      if (length.length != tableLength || safeMinQtyLength.length != 0 || safeMaxQtyLength.length != 0) {
        this.$message.error('请输入正确的商品信息')
        return
      }
      this.loading = true
      let goodsIds = []
      this.formData.tableDataAvailable.forEach(item => {
        this.$set(item, 'storeId', this.storeId)
      })
      // let formAvailable = {
      //   storeId: this.storeId,
      //   goodsIds
      // }
      await saveOrUpdateAPI(this.formData.tableDataAvailable)
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      await this.getList()
      const res = await safeStockDetailAPI(this.storeId)
      if (res.rows && res.rows.length > 0) {
        this.formData.tableDataAvailable = res.rows
      } else {
        this.formData.tableDataAvailable = [{}]
      }
      this.loading = false
    },
    stroeClick (row) {
      this.handleRowClick(row) 
    },
    //点击方案信息当前行
    async handleRowClick (row) {
      // 切换行选中状态
      this.radioItem = row.storeId
      this.loadingAvailable = true
      this.selectPriceCaseName = row.storeName
      this.storeId = row.storeId
      const res = await safeStockDetailAPI(row.storeId)
      if (res.rows && res.rows.length > 0) {
        this.formData.tableDataAvailable = res.rows
      } else {
        this.formData.tableDataAvailable = [{}]
      }
      this.loadingAvailable = false
      // this.$message({
      //   message: '查询成功',
      //   type: 'success'
      // })
    },

    //选择商品方法
    selectShopInfo () {
      this.OpenGoods = true
    },

    //表格单元格回车事件
    keyup (name, event, index) {
      this.loadingAvailable = true
      //判断是否输入编码或条码
      if (this.formData.tableDataAvailable[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.formData.tableDataAvailable[`${index}`][`${name}`]
        }).then(response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //去重
            let isRepeat = this.formData.tableDataAvailable.every(
              item => item.goodsId != response.rows[0].goodsId
            )
            if (!isRepeat) {
              this.$message.error('当前表格已有此门店!')
              this.loadingAvailable = false
              return
            }
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.formData.tableDataAvailable, index, response.rows[0])
          } else {
            this.queryCode = this.formData.tableDataAvailable[`${index}`][`${name}`]
            this.OpenGoods = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.OpenGoods = true
      }
      this.loadingAvailable = false
    },
    //选中的商品
    getGoodS (value) {
      this.loadingAvailable = true
      if (value.length == 0) {
        this.loadingAvailable = false
        return
      }
      //去重
      value = value.filter(item => {
        return this.formData.tableDataAvailable.every(v => v.goodsId != item.goodsId)
      })
      if (value.length == 0) {
        this.loadingAvailable = false
        return
      }
      this.formData.tableDataAvailable.push(...value)
      this.formData.tableDataAvailable = this.formData.tableDataAvailable.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )
      this.loadingAvailable = false
    },
    //适用门店表格当中增加/减少一行方法
    rowAvailable (name, index, storeStandardId) {
      if (name === 'push') {
        this.formData.tableDataAvailable.splice(index + 1, 0, {})
      } else {
        //根据有没有id判断这是数据库的数据
        if (storeStandardId) {
          this.$confirm('此操作将永久删除该商品, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              this.loadingAvailable = true //删除接口 await
              safeStockRemoveAPI([storeStandardId]) //详情接口刷新页面重新获取数据 const res =
              await safeStockDetailAPI(this.storeId)
              if (res.rows && res.rows.length > 0) {
                this.formData.tableDataAvailable = res.rows
              } else {
                this.formData.tableDataAvailable = [{}]
              }
              this.loadingAvailable = false
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            })
            .catch(() => {})
        } else {
          if (this.formData.tableDataAvailable.length <= 1) return
          this.formData.tableDataAvailable.splice(index, 1)
        }
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter (row) {
      //获取当前行下标
      let eleIndex = this.formData.tableDataAvailable.indexOf(row)
      // 注意必须是使用两次深拷贝 因为 hoverRow 属性不是tableData原有的 则直接修改无效  所以两次深拷贝重新赋值
      let Arr = JSON.parse(JSON.stringify(this.formData.tableDataAvailable))
      for (let index = 0; index < Arr.length; index++) {
        const element = Arr[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.formData.tableDataAvailable = JSON.parse(JSON.stringify(Arr))
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave () {
      // 移除hover的事件
      for (let index = 0; index < this.formData.tableDataAvailable.length; index++) {
        const element = this.formData.tableDataAvailable[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    }
  }
}
</script>

<style lang="scss" scoped>
// 最大盒子
.wrap {
  width: 100%;
  padding: 6px 0 6px 10px;
  background-color: #eaeaea;
  //关系情况
  .content {
    display: flex;
    // align-items: center;
    // justify-content: center;
    //左边
    .cardDetail {
      // width: 35%;
      margin-right: 10px;
    }
    //商品表格卡片
    .cardAvailable {
      // width: 65%;
      margin-bottom: 6px;
      // 标题
      .cardTitle {
        padding-left: 10px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #dedede;
        .title {
          margin-right: calc(100% - 131px);
          color: #333333;
          font-weight: 700;
          font-size: 14px;
          padding-left: 6px;
          border-left: 3px solid #1596fd;
        }
      }
    }

    //表格
    .tableContent {
      padding: 6px 10px 0 10px;
    }
  }
}
//输入框原生属性 /** * 解决el-input设置类型为number时，中文输入法光标上移问题
::v-deep .el-input__inner {
  line-height: 1px !important;
} /** *
解决el-input设置类型为number时，去掉输入框后面上下箭头 **/
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>
