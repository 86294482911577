<template>
  <!-- 仓库盘盈单明细 -->
  <div class="check-detail">
    <StoreDetail :billType="billType" ref="storeDetail">
      <!-- 插槽 -->
      <template #selectOneArea="scoped">
        <!-- <div class="searchItem">
          <el-form-item label="单据日期" prop="billDate">
            <el-date-picker
              v-model="scoped.form.billDate"
              type="date"
              placeholder="选择日期"
              :disabled="scoped.disabled"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </div> -->
        <div class="searchItem">
          <el-form-item label="盘盈仓库" prop="inStoreId">
             <!-- 仓库自定义下拉框 -->
             <SelectFrame
              ref="selectStore"
              v-model="scoped.form.inStoreName"
              :tableData="scoped.storeData"
              :disabled="scoped.disabled"
              @getPitchId="scoped.getDiyselectId($event,'inStoreId','form')"
              @clickAdd="OpenStoreAdd = true"
              @click="OpenStore = true"
              :normalizer="scoped.normalizerStore"
            ></SelectFrame>
          </el-form-item>
        </div>
        <div class="searchItem">
          <!-- prop="deptId" -->
          <el-form-item label="盘点部门" >
              <el-select
                v-model="scoped.form.deptId"
                placeholder="请选择"
                filterable
                clearable
                :disabled="scoped.disabled"
                size="mini"
              >
                <el-option
                  v-for="item in deptData"
                  :key="item.deptId"
                  :label="item.deptName"
                  :value="item.deptId"
                />
              </el-select>
          </el-form-item>
        </div>
      </template>
    </StoreDetail>
    <!-- 选择仓库对话框 -->
    <selectStore :OpenStore.sync="OpenStore" @getStore="getStore"></selectStore>
    <!-- 新增仓库对话框 -->
    <storeAdd :OpenStoreAdd.sync="OpenStoreAdd" @getStore="getStore"></storeAdd>
  </div>
</template>

<script>
import StoreDetail from '@/views/components/bill/storeDetail.vue'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import selectStore from "@/views/components/selectStore"; //选择仓库组件
import storeAdd from "@/views/components/selectStore/storeAdd.vue"; //新增仓库组件
import { listDept } from '@/api/system/dept'
export default {
  name: 'storeMoveOutDetail',
  components: { StoreDetail, Treeselect,selectStore,storeAdd },
  data () {
    return {
      billType: '130107',
      //打开选择仓库开关
      OpenStore:false,
      //打开新增仓库开关
      OpenStoreAdd:false,
      getStore: [],
      deptData: [],
    }
  },
  
  methods: {
    getlistDept () {
      listDept({ pageNum: 1, pageSize: 1000 }).then(res => {
        this.deptData = res.data
      })
    },
     //调用storeDetail组件的方法  把选中的仓库/新增仓库传给下拉框
     getStore (event) {
      this.$refs.storeDetail.getStore(event,'form','storeId','selectStore',true)
    }
  },
  mounted () {
    this.getlistDept()
  },
}
</script>
<style lang="scss" scoped>
//部门下拉框
.treeselect{
  width: 160px;
}
</style>
