<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { getWarranty, dealDueGoods } from '@/api/store/analyse/qualityalert'
import { allStoreList } from '@/api/system/store' //仓库
import { getAllGoodsList } from '@/api/goods/goods' // 商品列表
export default {
  name: 'qualityalert',
  components: { TablePage },
  data () {
    return {
      options: {
        mutiSelect: true, // 多选
        listNo: true, // 序号
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        getListApi: getWarranty,
        buttons: [
          {
            click: "handleGoods",
            label: "处理商品",
            // icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        body: {}, // 请求内容
        check: [], // 选中数据
        rowKey: "bookDetailId",
        labelWidth: "120",
        title: '保质期',
        search: [
          {
            label: '商品编码',
            type: 'input',
            model: '',
            filter: 'goodsNo'
          },
          {
            label: '商品名称',
            type: 'input',
            model: '',
            filter: 'goodsName'
          },
          {
            type: 'input',
            label: '条码',
            model: '',
            filter: 'barcode'
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级搜索',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' },
          // {
          //   label: '仓库',
          //   type: 'local',
          //   seniorSearch: true,
          //   model: '',
          //   filter: 'storeId',
          //   option: {
          //     filterable: true,
          //     value: 'storeId',
          //     label: 'storeName',
          //     remote: allStoreList,
          //     dataKey: 'data'
          //   }
          // },
          this.$select({ key: "listGoods",option: { seniorSearch: true, option: { multiple: true }}}),
          this.$select({ key: "listStore",option: { seniorSearch: true, option: { multiple: true }}}),
          {
            label: '剩余到期天数',
            type: 'number',
            seniorSearch: true,
            defaultValue: undefined,
            model: undefined,
            filter: 'remainingDueDays'
          },

          {
            type: 'date',
            label: '到期日期',
            seniorSearch: true,
            model: '',
            filter: 'dueDate'
          }
        ],
        columns: [
          {
            prop: 'goodsNo',
            label: '商品编码',
            minWidth: 100
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 80
          },
          {
            prop: 'barcode',
            label: '条码',
            minWidth: 100
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 60
          },
          {
            prop: 'unitName',
            label: '基本单位',
            minWidth: 100
          },
          {
            prop: 'storeNo',
            label: '仓库编号',
            minWidth: 100
          },
          {
            prop: 'storeName',
            label: '仓库名称',
            minWidth: 80
          },
          {
            prop: 'finalQty',
            label: '当前库存', // 及时库存
            minWidth: 80
          },
          {
            prop: 'produceDate',
            label: '生产日期',
            minWidth: 100
          },
          {
            prop: 'qualityDays',
            label: '保质期',
            minWidth: 100
          },
          {
            prop: '11231111',
            label: '保质期单位',
            formatter: () => '天',
            minWidth: 90
          },
          {
            prop: 'dueDate',
            label: '到期日期',
            minWidth: 100
          },
          {
            prop: 'qualityAlertDays',
            label: '预警天数',
            minWidth: 100
          },
          {
            prop: 'remainingDueDays',
            label: '剩余到期天数',
            minWidth: 100
          },
          {
            prop: 'alertDate',
            label: '预警日期',
            minWidth: 100
          },
          {
            prop: 'inQty',
            label: '入库数量',
            minWidth: 120
          },
          {
            prop: 'qualityDealTypeName',
            label: '处理状态',
            minWidth: 120
          },
          {
            prop: 'qualityDealBy',
            label: '处理人',
            minWidth: 120
          },
          {
            prop: 'qualityDealTime',
            label: '处理时间',
            minWidth: 160
          },
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      switch (type) {
        case 'handleGoods':
          const selectData = this.options.check.map((i) => i.bookDetailId);
          console.log(selectData, 'selectData');
          const $this = this
          dealDueGoods({bookDetailIds: selectData}).then((res) => {
            this.$message.success('处理成功')
            //新增
            $this.$refs.tablePage.getList();
          })
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
