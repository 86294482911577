var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "wrap",
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "cardTitleCom",
        { attrs: { cardTitle: "组装拆卸关系" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticStyle: { padding: "10px 10px 10px 20px" } },
              [
                _c("seniorSearch", {
                  attrs: {
                    isBillDate: false,
                    filterTimeStr: "thisMonth",
                    getList: _vm.getList,
                    dropDownList: _vm.dropDownList,
                    isSearchInput: true,
                    isSearchInputTitle:
                      "单据编号、订货仓库编号、名称、调出仓库编号、名称",
                    isAdvancedSearch: false,
                  },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "content",
          attrs: {
            model: _vm.options,
            rules: _vm.rules,
            "label-width": "106px",
          },
        },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "left flex1 mr20",
              attrs: { cardTitle: "商品信息" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("EditTable", {
                      attrs: { options: _vm.options },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                    _c("bottomPage", {
                      attrs: {
                        getList: _vm.getList,
                        idsCom: _vm.ids,
                        totalCom: _vm.total,
                      },
                      on: { selectAllCom: _vm.selectAll },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              staticClass: "right flex1",
              attrs: { cardTitle: "商品明细信息" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("EditTable", {
                      attrs: { options: _vm.sourceOptions },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }