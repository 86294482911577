<template>
  <StoreMoveOut />
</template>

<script>
import StoreMoveOut from './components'
export default {
  name: 'storeMoveOut',
  components: { StoreMoveOut }
}
</script>
