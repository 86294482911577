<template>
  <!-- 表格 -->
  <div class="all-table">
    <!--  :max-height="tableHeight" -->
    <el-table
      ref="Table"
      :data="tableData"
      style="width: 100%"
      border
      height="600"
      empty-text="暂无数据"
      class="myTable"
      show-summary
      :summary-method="getSummaries"
      v-loading="loading"
    >
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
        fixed="left"
      >
      </el-table-column>
      <!-- 表格最先列 -->
      <el-table-column
        v-for="item in firstLabel"
        :key="item.label"
        :label="item.label"
        align="center"
        :prop="item.prop"
        show-overflow-tooltip
        width="160"
        fixed="left"
      >
      </el-table-column>
      <!-- 可点击编号 -->
      <el-table-column
        v-if="NoLabel"
        :label="NoLabel[0]"
        align="center"
        :prop="NoLabel[1]"
        show-overflow-tooltip
        width="260"
        fixed="left"
      >
        <template slot-scope="scope">
          <el-button @click.native.prevent="clickNo(scope.row)" type="text">{{
            scope.row[`${NoLabel[1]}`]
          }}</el-button>
        </template>
      </el-table-column>
      <!-- 表格其他列 -->
      <el-table-column
        v-for="item in columnLabel"
        :key="item.label"
        :label="item.label"
        align="center"
        :prop="item.prop"
        show-overflow-tooltip
        :width="columnWidth"
      >
      </el-table-column>
      <!-- 表格展示二级表格第一个 -->
      <el-table-column
        v-if="secondLevelOneLabel"
        :label="secondLevelOneLabel[0].label"
        align="center"
        :prop="secondLevelOneLabel[0].prop"
        show-overflow-tooltip
      >
        <el-table-column
          v-for="item in secondLevelOneLabel[0].children"
          :key="item.label"
          :label="item.label"
          align="center"
          :prop="item.prop"
          show-overflow-tooltip
          :width="columnWidth"
        >
        </el-table-column>
      </el-table-column>
      <!-- 表格展示二级表格第二个 -->
      <el-table-column
        v-if="secondLevelTwoLabel"
        :label="secondLevelTwoLabel[0].label"
        align="center"
        :prop="secondLevelTwoLabel[0].prop"
        show-overflow-tooltip
      >
        <el-table-column
          v-for="item in secondLevelTwoLabel[0].children"
          :key="item.label"
          :label="item.label"
          align="center"
          :prop="item.prop"
          show-overflow-tooltip
          :width="columnWidth"
        >
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fcount } from '@/utils'
export default {
  name: 'AllTable',
  props: {
    tableData: {
      //表格数据
      required: true,
      type: Array
    },
    tableHeight: {
      //表格高度
      type: String,
      default: '650'
    },
    firstLabel: {
      //表格第一列字段
      type: Array
    },
    NoLabel: {
      //编号字段
      type: Array,
      default: false
    },
    columnLabel: {
      //表格其他列字段
      required: true,
      type: Array
    },
    columnWidth: {
      //表格列宽度
      type: String
    },
    secondLevelOneLabel: {
      //表格二级列字段第一个
      type: Array
    },
    secondLevelTwoLabel: {
      //表格二级列字段第二个
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    //点击编码事件
    clickNo (row) {
      this.$emit('getRowData', row)
    },
    getSummaries(param) {
        console.log(param, 'param')
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          }
          if (
            column.property === 'firstQty' ||
            column.property === 'firstMoney' ||
            column.property === 'inMoney' ||
            column.property === 'inQty' ||
            column.property === 'outQty' ||
            column.property === 'outMoney' ||
            column.property === 'finalQty' ||
            column.property === 'finalMoney'
          ) {
            //附加费
            const values = data.filter(
              item => !(item[`${column.property}`] === undefined)
            )
            // console.log('合计 222222222222', data, column.property);
            const values2 = values.map(i => Number(i[`${column.property}`]))
            sums[index] = values2
              .reduce((prev, curr) => {
                if (!isNaN(curr) && typeof curr === 'number') {
                  return fcount([prev, curr], "+");
                } else {
                  return prev;
                }
              }, 0)
          }
        })
        return sums
      }
  }
}
</script>

<style lang="scss" scoped>
.myTable {
  ::v-deep.el-table__fixed {
    height: auto !important;
    bottom: 10px;
  }
}
</style>
