<template>
  <!-- 调拨差异单 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import {
  franchiseDifferenceOutList,
  franchiseDifferenceList
} from "@/api/joinClient/bill/moveDiff";//加盟配送差异
import TablePage from "@/components/tablePage";
import { allStoreList } from "@/api/system/store"; //仓库
import { updateInstoreStatus } from "@/api/purchase/index";
import { differenceList } from "@/api/store/index";

const { cloneDeep } = require("lodash");

export default {
  name: "unsaleGoods",
  components: { TablePage },
  props: {
    shopType: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        labelWidth: "100",
        body: {},
        getListApi: this.shopType == 2 ? franchiseDifferenceOutList : this.shopType == 3 ? franchiseDifferenceList : differenceList,
        defaultBody: {},
        listNo: true, // 序号
        title: "单据信息",
        check: [], // 选中数据
        rowKey: "inBillDetailId",
        search: [
          {
            label: "单据日期",
            type: "datetimerange",
            model: "",
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            label: "业务类型",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "billCustomType",
            option: {
              data: [
                {
                  billCustomType: "1",
                  billCustomTypeName: "同价调拨",
                },
                {
                  billCustomType: "2",
                  billCustomTypeName: "异价调拨",
                },
              ],
              dataKey: "data",
              label: "billCustomTypeName",
              value: "billCustomType",
              filterable: true,
            },
          },
          this.$select({
            key: "listOutStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listInStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          {
            label: "差异处理结果",
            seniorSearch: true,
            type: "local",
            model: "",
            filter: "differenceProcessResults",
            option: {
              data: [
                {
                  differenceProcessResult: 1,
                  differenceProcessResultName: "未处理",
                },
                {
                  differenceProcessResult: 2,
                  differenceProcessResultName: "已处理",
                },
              ],
              labels: [
                // { title: '编号', label: 'differenceProcessResult' },
                { title: "名称", label: "differenceProcessResultName" },
              ],
              dataKey: "data",
              label: "differenceProcessResultName",
              value: "differenceProcessResult",
              filterable: true,
              multiple: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        // buttons: [
        //   {
        //     click: 'add',
        //     label: '新增',
        //     icon: 'el-icon-plus',
        //     type: 'primary',
        //     disabled: false
        //   },
        //   {
        //     click: 'audit',
        //     label: '审核',
        //     type: 'success',
        //     btnType: 'dropdown',
        //     disabled: false,
        //     other: [
        //       {
        //         click: 'redoAudit',
        //         label: '反审核',
        //         disabled: false
        //       }
        //     ]
        //   },
        //   {
        //     click: 'del',
        //     label: '删除',
        //     type: 'danger',
        //     icon: 'el-icon-delete',
        //     disabled: false
        //   },
        //   {
        //     click: 'stop',
        //     label: '终止',
        //     icon: 'el-icon-remove-outline',
        //     type: 'primary',
        //     disabled: false
        //   }
        // ],
        buttons: [
          {
            click: "createAdjustIn",
            label: "库存调整入库单",
            type: "primary",
            disabled: false,
          },
          {
            click: "createAdjustOut",
            label: "库存调整出库单",
            type: "success",
            disabled: false,
          },
        ],
        columns: [
          {
            prop: "auditTime",
            label: "单据时间",
            formatter: (v, row) => row.inBillInfo.auditTime,
            // type: 'link',
            // click: 'routerLink',
            minWidth: 180,
          },
          {
            prop: "billCustomTypeName",
            label: "业务类型",
            formatter: (v, row) => row.outBillInfo.billCustomTypeName,
            minWidth: 120,
          },
          {
            prop: "billStatusName",
            label: "调拨状态",
            formatter: (v, row) => row.inBillInfo.billStatusName,
            // type: 'dict',
            minWidth: 120,
            // dict: 'bill_status'
          },
          {
            prop: "billNo",
            label: "调拨出库单编号",
            type: "link",
            click: "routerLinkToMoveOut",
            formatter: (v, row) => row.outBillInfo.billNo,
            minWidth: 160,
          },
          {
            prop: "billNo",
            label: "调拨入库单编号",
            type: "link",
            click: "routerLinkToMoveIn",
            formatter: (v, row) => row.inBillInfo.billNo,
            type: "link",
            minWidth: 160,
          },
          {
            prop: "billNo",
            label: "差异处理结果单",
            type: "link",
            click: "routerLinkToAdjust",
            formatter: (v, row) => row.differenceBillInfo.billNo,
            minWidth: 160,
          },
          {
            prop: "differenceResult",
            label: "差异处理结果",
            formatter: (v, row) => {
              if (
                row.differenceBillInfo &&
                row.differenceBillInfo != null &&
                row.differenceBillInfo.billId &&
                row.differenceBillInfo.billId != null
              ) {
                return "已处理";
              }
              return "未处理";
            },
            minWidth: 140,
          },
          {
            prop: "billStatusName",
            label: "差异处理状态",
            formatter: (v, row) => {
              if (
                row.differenceBillInfo &&
                row.differenceBillInfo.billStatusName &&
                row.differenceBillInfo.billStatusName != null
              ) {
                return row.differenceBillInfo.billStatusName;
              } else {
                return "";
              }
            },
            minWidth: 160,
          },
          {
            prop: "outStoreName",
            label: "调出仓库",
            formatter: (v, row) => row.outBillInfo.outStoreName,
            minWidth: 120,
          },
          {
            prop: "inStoreName",
            label: "调入仓库",
            formatter: (v, row) => row.inBillInfo.inStoreName,
            minWidth: 120,
          },
          {
            prop: "goodsNo",
            label: "商品编码",
            formatter: (v, row) => row.goodsInfo.goodsNo,
            minWidth: 120,
          },
          {
            prop: "goodsName",
            label: "商品名称",
            formatter: (v, row) => row.goodsInfo.goodsName,
            minWidth: 120,
          },
          {
            prop: "barcode",
            label: "商品条码",
            formatter: (v, row) => row.goodsInfo.barcode,
            minWidth: 120,
          },
          {
            prop: "goodsSpec",
            label: "规格",
            formatter: (v, row) => row.goodsInfo.goodsSpec,
            minWidth: 120,
          },
          {
            prop: "unitName",
            label: "单位",
            formatter: (v, row) => row.goodsInfo.unitName,
            minWidth: 120,
          },
          {
            prop: "unitQty",
            label: "出库数量",
            formatter: (v, row) => row.outBillInfo.unitQty,
            minWidth: 120,
          },
          {
            prop: "unitQty",
            label: "入库数量",
            formatter: (v, row) => row.inBillInfo.unitQty,
            minWidth: 120,
          },
          {
            prop: "diffQty",
            label: "差异数量",
            formatter: (v, row) => row.inBillInfo.unitQty - row.outBillInfo.unitQty,
            minWidth: 120,
          },
          {
            prop: "remark",
            label: "备注",
            formatter: (v, row) => row.inBillInfo.remark,
            minWidth: 120,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      // let url = '/api/system/store/bill/in/updateBillStatus'
      // let billType = ''
      switch (type) {
        case "routerLinkToAdjust":
          {
            const billId = row.differenceBillInfo.billId;
            this.$router.push({
              name: "storeAdjustDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "routerLinkToMoveOut":
          {
            // 130105 仓库调出单// 140306 门店调出
            const billId = row.outBillInfo.billId;
            this.$router.push({
              name: row.outBillInfo.billType == '130105' ? 'storeMoveOutDetail' : "MoveOutDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "routerLinkToMoveIn":
          {
            // 130106 仓库调入单 // 140305 门店调入
            const billId = row.inBillInfo.billId;
            this.$router.push({
              name: row.inBillInfo.billType == '130106' ? 'storeMoveInDetail' : "MoveInDetail",
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "createAdjustIn":
          {
            this.createAdjust(selectData, "1");
          }
          break;
        case "createAdjustOut":
          {
            this.createAdjust(selectData, "2");
          }
          break;
        case "getListed":
          {
            this.options.body.billStatus = undefined;
          }
          break;
        default:
          break;
      }
    },
    seniorSearch() { },
    createAdjust(selectData, billPostType) {
      if (!selectData.length) return;

      //调整单据头
      let adjustBillInfo = {
        storeId: null,
        storeName: null,
        billPostType: billPostType,
        scrapTypeId: "0",
        billType: "130102",
        details: [],
      };

      let isAllowOperate = true;
      for (let i = 0; i < selectData.length; i++) {
        const t = selectData[i];
        if (adjustBillInfo.storeId === null) {
          adjustBillInfo.storeId = t.outBillInfo.outStoreId;
          adjustBillInfo.storeName = t.outBillInfo.outStoreName;
        }

        if (adjustBillInfo.storeId !== t.outBillInfo.outStoreId) {
          isAllowOperate = false;
          this.$message({
            message: "请选择同一仓库差异单",
            type: "warning",
          });
          break;
        }
        if (
          t.differenceBillInfo &&
          t.differenceBillInfo !== null &&
          t.differenceBillInfo.billId &&
          t.differenceBillInfo.billId !== null
        ) {
          isAllowOperate = false;
          this.$message({
            message: "请勿重复处理差异",
            type: "warning",
          });
          break;
        }

        let unitQty = t.inBillInfo.unitQty - t.outBillInfo.unitQty;

        if (unitQty < 0 && billPostType !== "1") {
          isAllowOperate = false;
          this.$message({
            message: "请操作生成其他增加单",
            type: "warning",
          });
          break;
        } else if (unitQty > 0 && billPostType !== "2") {
          isAllowOperate = false;
          this.$message({
            message: "请操作生成其他减少单",
            type: "warning",
          });
          break;
        } else if (unitQty === 0) {
          isAllowOperate = false;
          this.$message({
            message: "调入与调出数量相等无效处理差异",
            type: "warning",
          });
          break;
        }

        unitQty = Math.abs(unitQty);

        adjustBillInfo.details.push({
          goodsId: t.goodsInfo.goodsId,
          goodsName: t.goodsInfo.goodsName,
          barcode: t.goodsInfo.barcode,
          goodsSpec: t.goodsInfo.goodsSpec,
          goodsNo: t.goodsInfo.goodsNo,
          unitName: t.goodsInfo.unitName,
          unitId: t.goodsInfo.unitId,
          unitPrice: t.inBillInfo.unitPrice,
          unitQty: unitQty,
          unitMoney: (unitQty * t.inBillInfo.unitPrice).toFixed(3),
          batchNo: t.inBillInfo.batchNo,
          storeId: t.outBillInfo.outStoreId,
          sourceBillId: t.inBillInfo.billId,
          sourceBillNo: t.inBillInfo.billNo,
          sourceBillDetailId: t.inBillInfo.billDetailId,
          sourceBillType: t.inBillInfo.billType,
          units: [
            {
              unitId: t.goodsInfo.unitId,
              unitName: t.goodsInfo.unitName,
            },
          ],
        });
      };

      if (!isAllowOperate) {
        return;
      }

      this.$router.push({
        name: "storeAdjustDetail",
        query: {
          type: "Add",
          addType: "moveDiffsProcess",
        },
        params: {
          adjustBillInfo: adjustBillInfo,
        },
      });
    },
    seniorSearch() { },
    handleBillStatus(command, selectData, url, BillType) {
      let billData = []; //过滤的数据
      let name = ""; //要执行的状态名字
      let tag = ""; //提示的状态
      let billStatus = ""; //要执行的状态数字
      if (command === "反审核") {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = "反审核";
        tag = "已审核";
        billStatus = "0";
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === "审核") {
          name = "审核";
          tag = "未审核";
          billStatus = "2";
        } else if (command === "删除") {
          name = "删除";
          tag = "未审核";
          billStatus = "3";
        } else if (command === "关闭") {
          name = "关闭";
          tag = "未审核";
          billStatus = "4";
        }
      }
      const billId = selectData.map((i) => i.billId);
      const billNo = selectData.map((i) => i.billNo);
      const $this = this;
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus([...billId], billStatus, url, BillType).then(() => {
            $this.$nextTick(() => {
              $this.options.check = [];
            });
            $this.$modal.msgSuccess(`${name}成功`);
            setTimeout(() => {
              $this.$refs.tablePage.getList();
            }, 500);
          });
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      //从首页待办业务进来
      if (to.query.billStatus && from.fullPath == "/index") {
        vm.options.defaultBody = { billStatus: to.query.billStatus };
        let cloneData = cloneDeep(vm.options);
        vm.options = null;
        vm.options = cloneData;
      }
    });
  },
};
</script>
<style lang="scss" scoped></style>
